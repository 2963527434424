

import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import axios from 'axios';
import { API_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import './content.css';


const useStyles = theme => ({
  paper: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
   // alignItems: 'center',
    maxWidth:700,
    maxHeight:2000,
   
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom:'20px'
  },
  flexZone: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  topLabel:{
     width:'400px',
     height:'40px',
     background: '#D1152D',
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
  },
  rightTopHalfCircle:{
    height:'40px',
    width:'20px',
    borderRadius: '90px 0px 0px 90px',
    background: '#D1152D',
   
},
bottomRightTriangle:{
     width:'30px',
     height:'30px',
     background: '#D1152D',
     borderRadius: `90px 0 0px 0`
},

});
  
const TermsConditions =(props)=> {
 
    const { classes } = props;
    return (
      <Paper className={classes.paper}>

      <div className={classes.flexContainer}>
      <div className={classes.topLabel}>
      <Typography variant="body1"  color="secondary">
      Terms & Conditions
     </Typography>  </div>
     <div className={classes.rightTopHalfCircle}>  </div>
      </div>
   
      <div style={{margin: "10px"}} className="infoBody">
      <p>Please read these Terms and Conditions before using <a href="http://prodeap.com">sundarbanX</a>.</p>
<p>Welcome to <a href="http://prodeap.com">sundarbanX</a>!</p>
<p><a href="http://prodeap.com">sundarbanX</a> builds technologies and services that enable people to connect with each other, build communities, and grow businesses. These Terms govern your use of <a href="http://prodeap.com">sundarbanX</a> website, Apps, Services, Technologies, and Software we offer (the <a href="http://prodeap.com">sundarbanX</a> Product or Products), except where we expressly state that separate terms (and not these) apply. These Products are provided to you by <a href="http://prodeap.com">sundarbanX</a>.</p>
<p>Advertisers and users are fully responsible for ensuring that advertising content, text, images, graphics uploaded for inclusion on <a href="http://prodeap.com">sundarbanX</a>&nbsp; comply with all applicable laws. <a href="http://prodeap.com">sundarbanX</a> assumes no responsibility for any illegality or any inaccuracy of the content.</p>
<p>Advertisers grant <a href="http://prodeap.com">sundarbanX</a> royalty-free, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from and distribute such content or incorporate such content into any form, medium, or technology now known or later developed.</p>
<p>The advertisers and users guarantee that one&rsquo;s CONTENT does not violate any copyright, intellectual property rights or other rights of any person or entity, and agrees to release <a href="http://prodeap.com">sundarbanX</a> from all obligations, liabilities and claims arising in connection with the use of (or the inability to use) the service.</p>
<p><a href="http://prodeap.com">sundarbanX</a> reserves the right to change the title/description of the CONTENT, for editorial purposes. <a href="http://prodeap.com">sundarbanX</a> reserves the right not to publish images that are irrelevant or images that violate <a href="http://prodeap.com">sundarbanX</a>&rsquo;s rules.</p>
<p><a href="http://prodeap.com">sundarbanX</a> will collect information from USER and ADVERTISERS. It is a condition of use that each USERS and ADVERTISER consents and authorizes <a href="http://prodeap.com">sundarbanX</a> to collect and use this information. <a href="http://prodeap.com">sundarbanX</a> also reserves the right to disclose it to Company Affiliates and any other person for the purposes of administering, supporting and maintaining it, as well as for improving, for example by using the information for research, marketing, product development, and planning.</p>
<p><a href="http://prodeap.com">sundarbanX</a> uses cookies, which means that you must have cookies enabled on your computer in order for all functionality on this site to work properly. A cookie is a small data file that is written to your hard drive when you visit certain Websites. Cookie files contain certain information, such as a random number user ID that the site assigns to a visitor to track the pages visited. A cookie cannot read data off your hard disk or read cookie files created by other sites. Cookies, by themselves, cannot be used to find out the identity of any user.</p>
<p>Users are required to submit a Valid Mobile Phone Number before they are allowed to post advertisements. The Mobile Phone Number of the User is allowed to be publicly displayed on the advertisement and contact details field and other users are permitted to call or send SMS to the User for <a href="http://prodeap.com">sundarbanX</a> related issues.</p>
<p><a href="http://prodeap.com">sundarbanX</a> does not guarantee continuous or secure access to the Web Site. The Web Site is provided "as is" and as and when available.</p>
<p><a href="http://prodeap.com">sundarbanX</a> may contain links or references to other websites (&ldquo;Third Party Websites&rdquo;). It will not be responsible for the contents of Third Party Websites. Third Party Web sites are not investigated or monitored. In the event the user decides to leave <a href="http://prodeap.com">sundarbanX</a> and access Third Party Sites, the user does so at his/her own risk.</p>
<p>We charge you to advertise at prodeap.com. Businesses and organizations pay us to show you promotional ads for their products and services. By using our Products, you agree that we can show you ads that we think will be relevant to you and your interests. We use your personal data to help determine which ads to show you.</p>
<p>We don&rsquo;t sell your personal data to advertisers, and we don&rsquo;t share information that directly identifies you (such as your name, email address, or other contact information) with advertisers unless you give us specific permission. Instead, advertisers can tell us things like the kind of audience they want to see their ads, and we show those ads to people who may be interested.</p>
<p>All Users are allowed to post Items in Bengali and English.</p>
<p><a href="http://prodeap.com">sundarbanX</a> reserves the right to modify these Terms and Conditions. Such modifications shall be effective immediately upon update on <a href="http://prodeap.com">sundarbanX</a>. You are completely responsible for the reviewing of such modifications. Your continued access or use of <a href="http://prodeap.com">sundarbanX</a>&nbsp; will be deemed your acceptance of the modified terms and conditions.</p>

    </div>
          
       <div className={classes.flexZone}>
      
      <div className={classes.bottomRightTriangle}>  </div>
       </div>
       <ToastContainer autoClose={2000} />
      </Paper>
    );
  }

export default withStyles(useStyles)(TermsConditions);
