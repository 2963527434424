import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const Footer = () => {
  return (
    <div>
      <Link to='/about' style={{ color: '#D1152C' }}>
        {' '}
        About Us |{' '}
      </Link>
      <Link to='/contact-us' style={{ color: '#D1152C' }}>
        {' '}
        Contact Us |{' '}
      </Link>
      <Link to='/privacy-policy' style={{ color: '#D1152C' }}>
        {' '}
        Privacy Policy |{' '}
      </Link>
      <Link to='/terms-conditions' style={{ color: '#D1152C' }}>
        {' '}
        Terms & Conditions |{' '}
      </Link>
      <Link to='/privacy' style={{ color: '#D1152C' }}>
        {' '}
        Privacy{' '}
      </Link>
      <Typography variant='body2'>© 2020 - sundarbanX</Typography>
    </div>
  );
};

export default Footer;
