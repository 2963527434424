import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withStyles, Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL, BASEIMAGE } from "../../Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonCard from "../../layouts/SkeletonCard";
import ConfirmDialog from "../utilities/ConfirmDialog";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    maxHeight: theme.spacing(50),
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
  triangleBottomRight: {
    width: 0,
    height: 0,
    borderRight: "110px solid #D1152C",
    borderTop: "35px solid transparent",
    borderLeft: "300px solid transparent",
    borderBottom: "50px solid #D1152C",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  spec: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
  },
  large: {
    height: 140,
    width: 140,
  },
}));

const ManageTeam = (props) => {
  const classes = useStyles();
  const history = useHistory();

  let [teams, setTeams] = React.useState([]);
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log("inside m: ");
    getTeamBySellerId(JSON.parse(localStorage.getItem("user")).Id);
  }, []);

  const getTeamBySellerId = (id) => {
    setLoading(true);
    axios.get(API_URL + "UserTeams/ByUser?userId=" + id).then((res) => {
      setTeams(res.data);
      setLoading(false);
      console.log(JSON.stringify(res.data));
    });
  };

  const onDelete = (guid) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    axios.delete(API_URL + "UserTeams/" + guid).then(
      (res) => {
        toast.success("Team Deleted Successfully");
        getTeamBySellerId(JSON.parse(localStorage.getItem("user")).Id);
      },
      (error) => {
        toast.error("Team Deletion Failed !!!");
      }
    );
  };

  const [hasMore, setHasMore] = React.useState(true);
  let [page, setPage] = React.useState(0);
  const fetchMoreData = () => {
    page++;
    setPage(page);
    axios
      .get(
        API_URL +
          "UserTeams" +
          JSON.parse(localStorage.getItem("user")).Id +
          "&pageIndex=" +
          page
      )
      .then((response) => {
        if (response.data.length < 10) setHasMore(false);
        setTeams(teams.concat(response.data));
      });
  };

  return loading ? (
    <Typography variant="body2">
      <h4>Loading...</h4>
    </Typography>
  ) : !loading && teams.length === 0 ? (
    <p style={{ textAlign: "center", color: "#d1152c" }}>No Teams Available</p>
  ) : (
    <InfiniteScroll
      dataLength={teams.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<SkeletonCard />}
      scrollableTarget="scrollableDiv"
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {teams?.map((team, index) => (
        <Paper className={classes.root} key={index}>
          <div style={{ alignSelf: "center" }}>
            <div className={classes.spec}>
              <Typography variant="subtitle2" color="primary">
                Name:
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                &nbsp;{team.Name}
              </Typography>
            </div>

            <Typography variant="subtitle2">Email: {team.Email}</Typography>
            <Typography variant="subtitle2">Contact: {team.Phone}</Typography>
          </div>
          <div className={classes.flexContainer}>
            <Button
              type="submit"
              onClick={() => {
                history.push("/connections-for-team/" + team.Id);
              }}
              color="secondary"
              style={{
                borderRadius: 25,
                textTransform: "none",
                width: 100,
                marginBottom: "5px",
                color: "#D1152C",
              }}
              variant="contained"
            >
              <AddIcon></AddIcon> Member
            </Button>
            <Button
              type="submit"
              onClick={() => {
                history.push("/edit-team/" + team.Id);
              }}
              color="secondary"
              style={{
                borderRadius: 25,
                textTransform: "none",
                width: 100,
                marginBottom: "5px",
                color: "#D1152C",
              }}
              variant="contained"
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this Team?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    onDelete(team.Id);
                  },
                });
              }}
              type="submit"
              color="primary"
              style={{ borderRadius: 25, textTransform: "none", width: 100 }}
              variant="contained"
            >
              Delete
            </Button>
          </div>
        </Paper>
      ))}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ToastContainer autoClose={2000} />
    </InfiniteScroll>
  );
};

export default withStyles(useStyles)(ManageTeam);
