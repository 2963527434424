import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import {
  GridList,
  GridListTile,
  Typography,
  Select,
  Avatar,
} from "@material-ui/core";
import { IMAGE_URL, API_URL, BASEIMAGE } from "../../Constants";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import SelectInput from "@material-ui/core/Select/SelectInput";
import LogoShop from "../../images/logo-shop1.png";
const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  textFieldInput: {
    width: "300px",
  },
  uploadField: {
    position: "relative",
    display: "inline-flex",
    background: "#ffffff",
  },
  btnUpload: {
    position: "absolute",
    top: 0,
    right: 0,
    borderRadius: 25,
    textTransform: "none",
    padding: "9px 35px",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: "10px 0",
  },
  signboard: {
    width: theme.spacing(30),
    margin: "10px 0",
  },
}));
const EditShop = (props) => {
  const classes = useStyles();
  const {
    values: {
      Name,
      ShopTypeId,
      Phone,
      Email,
      StateId,
      CityId,
      Address,
      Description,
      LogoPath,
      SignboardPath,
      LogoPictureId,
      SignboardPictureId,
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
  } = props;

  let [shopTypes, setShopTypes] = React.useState([]);
  let [states, setStates] = React.useState([]);
  let [cities, setCities] = React.useState([]);
  let [districtid, setDistrictid] = React.useState(0);
  let [logoFileName, setLogoFileName] = React.useState("");
  let [signboardFileName, setSignboardFileName] = React.useState("");

  React.useEffect(() => {
    setDistrictid(StateId);
    axios.get(API_URL + "ShopTypes").then((response) => {
      console.log(response);
      setShopTypes(response.data);
    });
    axios.get(API_URL + "States").then((response) => {
      console.log(response);
      setStates(response.data);
    });
    axios.get(API_URL + "Cities").then((response) => {
      console.log(response);
      setCities(response.data);
    });
  }, []);

  const change = (name, e) => {
    console.log(name);
    console.log(e);
    if (name === "StateId") {
      setDistrictid(e.target.value);
    }
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const hiddenFileInput = React.useRef(null);
  const hiddenSignboardFileInput = React.useRef(null);

  const handleUploadLogo = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUploadSignboard = (event) => {
    hiddenSignboardFileInput.current.click();
  };
  const handleFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    let reader = new FileReader();
    reader.readAsDataURL(fileUploaded);

    reader.onloadend = (e) => {
      let imageCode = reader.result.toString().split(",")[1];
      console.log(imageCode);
      axios
        .post(API_URL + "Uploads/Photo", {
          Title: fileUploaded.name.toString().split(".")[0],
          Code: imageCode,
          Type: "Logo",
        })
        .then((res) => {
          toast.warn("Image Uploading");

          if (res.data.Meta.Status === 0) toast.error(res.data.Meta.Message);
          else {
            setLogoFileName(res.data.FullPath);
            setFieldValue("LogoPictureId", res.data.Data.Id);
            toast.success(res.data.Meta.Message);
          }
        });
    };
  };

  const handleSignboardFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    let reader = new FileReader();
    reader.readAsDataURL(fileUploaded);

    reader.onloadend = (e) => {
      let imageCode = reader.result.toString().split(",")[1];
      console.log(imageCode);
      axios
        .post(API_URL + "Uploads/Photo", {
          Title: fileUploaded.name.toString().split(".")[0],
          Code: imageCode,
          Type: "Signboard",
        })
        .then((res) => {
          toast.warn("Image Uploading");

          if (res.data.Meta.Status === 0) toast.error(res.data.Meta.Message);
          else {
            setSignboardFileName(res.data.FullPath);
            setFieldValue("SignboardPictureId", res.data.Data.Id);
            toast.success(res.data.Meta.Message);
          }
        });
    };
  };
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant="outlined"
        id="Name"
        name="Name"
        helperText={touched.Name ? errors.Name : ""}
        error={touched.Name && Boolean(errors.Name)}
        label="Name"
        value={Name || ""}
        onChange={change.bind(null, "Name")}
        size="small"
        className={classes.marginBottom}
      />

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        select
        id="ShopTypeId"
        label="Select Shop Type"
        name="ShopTypeId"
        helperText={touched.ShopTypeId ? errors.ShopTypeId : ""}
        error={touched.ShopTypeId && Boolean(errors.ShopTypeId)}
        value={ShopTypeId || 0}
        onChange={change.bind(null, "ShopTypeId")}
        variant="outlined"
        size="small"
        className={classes.marginBottom}
      >
        {shopTypes.map((option) => (
          <MenuItem key={option.Id} value={option.Id}>
            {option.Title}
          </MenuItem>
        ))}
      </CustomTextField>

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant="outlined"
        id="Phone"
        name="Phone"
        helperText={touched.Phone ? errors.Phone : ""}
        error={touched.Phone && Boolean(errors.Phone)}
        label="Phone"
        size="small"
        className={classes.marginBottom}
        value={Phone || ""}
        onChange={change.bind(null, "Phone")}
      />

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant="outlined"
        id="Email"
        name="Email"
        helperText={touched.Email ? errors.Email : ""}
        error={touched.Email && Boolean(errors.Email)}
        label="Email"
        size="small"
        className={classes.marginBottom}
        value={Email || ""}
        onChange={change.bind(null, "Email")}
      />

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        select
        id="StateId"
        label="Select District"
        name="StateId"
        value={StateId || 0}
        onChange={change.bind(null, "StateId")}
        variant="outlined"
        size="small"
        className={classes.marginBottom}
      >
        {states.map((option) => (
          <MenuItem key={option.Id} value={option.Id}>
            {option.Name}
          </MenuItem>
        ))}
      </CustomTextField>

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        select
        id="CityId"
        label="Select Location"
        name="CityId"
        value={CityId || 0}
        onChange={change.bind(null, "CityId")}
        variant="outlined"
        size="small"
        className={classes.marginBottom}
      >
        {cities
          .filter((x) => x.StateId === StateId)
          .map((option) => (
            <MenuItem key={option.Id} value={option.Id}>
              {option.Name}
            </MenuItem>
          ))}
      </CustomTextField>

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant="outlined"
        id="Address"
        name="Address"
        helperText={touched.Address ? errors.Address : ""}
        error={touched.Address && Boolean(errors.Address)}
        label="Address"
        size="small"
        className={classes.marginBottom}
        value={Address || ""}
        onChange={change.bind(null, "Address")}
      />

      <TextField
        multiline
        rows="4"
        rowsMax="50"
        variant="outlined"
        fullWidth
        size="small"
        helperText={touched.Description ? errors.Description : ""}
        error={errors.Description && Boolean(errors.Description)}
        id="Description"
        value={Description ? Description.replaceAll("<br />", "\n") : ""}
        label="Description"
        name="Description"
        onChange={change.bind(null, "Description")}
        className={classes.marginBottom}
        style={{ width: "300px" }}
      />

      <div className={classes.uploadField}>
        <CustomTextField
          InputProps={{
            classes: {
              root: classes.textFieldInput,
            },
          }}
          disabled
          variant="outlined"
          name="SearchText"
          size="small"
          value="Select Logo"
          className={classes.marginBottom}
        />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <Button
          onClick={handleUploadLogo}
          size="small"
          variant="contained"
          color="primary"
          className={classes.btnUpload}
        >
          Choose
        </Button>
      </div>

      <Avatar
        className={classes.large}
        src={
          logoFileName === "" ? IMAGE_URL + LogoPath : BASEIMAGE + logoFileName
        }
        alt={Name}
      />

      <div className={classes.uploadField}>
        <CustomTextField
          InputProps={{
            classes: {
              root: classes.textFieldInput,
            },
          }}
          disabled
          variant="outlined"
          name="UploadSignboard"
          size="small"
          value="Select Signboard"
          className={classes.marginBottom}
        />
        <input
          type="file"
          ref={hiddenSignboardFileInput}
          onChange={handleSignboardFileChange}
          style={{ display: "none" }}
        />
        <Button
          onClick={handleUploadSignboard}
          size="small"
          variant="contained"
          color="primary"
          className={classes.btnUpload}
        >
          Choose
        </Button>
      </div>

      <img
        className={classes.signboard}
        src={
          signboardFileName === ""
            ? IMAGE_URL + SignboardPath
            : BASEIMAGE + signboardFileName
        }
        alt="image"
      />

      <Button
        type="submit"
        size="small"
        variant="contained"
        color="primary"
        className={classes.marginBottom}
        disabled={!isValid}
        style={{ borderRadius: 25, textTransform: "none", padding: "9px 25px" }}
      >
        Update Shop
      </Button>
    </form>
  );
};

export default EditShop;
