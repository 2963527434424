import React from "react";
import NavBar from "./components/Navbar";
import MiddlePane from "./components/MiddlePane";
import LoginFormFormic from "./components/auth/LoginFormFormic";
import PasswordRecoveryFormic from "./components/auth/PasswordRecoveryFormic";
import { Route, Switch, withRouter } from "react-router-dom";
import ShopList from "./components/Shops/ShopList";
import AddShopFormic from "./components/Shops/AddShopFormic";
import Dashboard from "./components/dashboard/Dashboard";
import MyAdList from "./components/AD/MyAdList";
import ReferFriendFormic from "./components/friends/ReferFriendFormic";
import ReferredFriendList from "./components/friends/ReferredFriendList";
import MyProfile from "./components/my-profile/MyProfile";
import EditProfileFormic from "./components/my-profile/EditProfileFormic";
import UserFeedbackFormic from "./components/feedback/UserFeedbackFormic";
import AdDetail from "./components/AD/AdDetail";
import LoginLayoutRoute from "./layouts/LoginLayout";
import HomepageLayoutRoute from "./layouts/HomepageLayout";
import SecondaryLayoutRoute from "./layouts/SecondaryLayout";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import RegisterByMobileFormic from "./components/auth/RegisterByMobileFormic";
import DetailsPageLayoutRoute from "./layouts/DetailsPageLayout";
import ManageShop from "./components/Shops/ManageShop";
import EditShopFormic from "./components/Shops/EditShopFormic";
import ShopLayoutRoute from "./layouts/ShopLayout";
import ShopDetail from "./components/Shops/ShopDetail";
import MyShopDetail from "./components/Shops/MyShopDetail";
import MyConnections from "./components/People/MyConnections";
import ConnectList from "./components/People/ConnectList";
import PublicProfile from "./components/my-profile/PublicProfile";
import ShopLayout from "./layouts/ShopLayout";
import RewardsPoint from "./components/Rewards/RewardsPoint";
import Payment from "./components/Payment/Payment";
import ProfileLayout from "./layouts/ProfileLayout";
import ProfileRoute from "./components/my-profile/ProfileRoute";
import About from "./components/content/About";
import ContactUs from "./components/content/ContactUs";
import PrivacyPolicy from "./components/content/PrivacyPolicy";
import TermsConditions from "./components/content/TermsConditions";
import Privacy from "./components/content/Privacy";
import RedirectMe from "./components/home/RedirectMe";
import RedirectPayment from "./components/home/RedirectPayment";
import RedirectAd from "./components/AD/RedirectAd";
import SecondaryLayoutFooterRoute from "./layouts/SecondaryLayoutFooter";
import TeamMember from "./components/People/TeamMember";
import EditTeamMemberFormic from "./components/People/EditTeamMemberFormic";
import AddTeamMemberFormic from "./components/People/AddTeamMemberFormic";
import ManageTeam from "./components/Team/ManageTeam";
import AddTeamFormic from "./components/Team/AddTeamFormic";
import EditTeamFormic from "./components/Team/EditTeamFormic";
import ConnectionsListForTeam from "./components/Team/ConnectionsListForTeam";
import TeamWork from "./components/Team/TeamWork";
import TeamInvitePeopleFormic from "./components/Team/TeamInvitePeopleFormic";

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#D1152C",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});
const App = withRouter(({ location }) => {
  return (
    <MuiThemeProvider theme={customTheme}>
      <React.Fragment>
        <Switch>
          {/* <Route path='/profile' component={ProfileRoute} /> */}

          <SecondaryLayoutFooterRoute path="/about" component={About} />
          <SecondaryLayoutFooterRoute
            path="/contact-us"
            component={ContactUs}
          />
          <SecondaryLayoutFooterRoute
            path="/privacy-policy"
            component={PrivacyPolicy}
          />
          <SecondaryLayoutFooterRoute
            path="/terms-conditions"
            component={TermsConditions}
          />
          <SecondaryLayoutFooterRoute path="/privacy" component={Privacy} />

          <Route path="/business/:link" component={ShopLayout} />
          <SecondaryLayoutRoute
            path="/user-feedback"
            component={UserFeedbackFormic}
          />
          <SecondaryLayoutRoute
            path="/edit-profile/:guid"
            component={EditProfileFormic}
          />
          <SecondaryLayoutRoute path="/my-profile" component={MyProfile} />

          <SecondaryLayoutRoute
            path="/invited-people-list"
            component={ReferredFriendList}
          />

          <SecondaryLayoutRoute
            path="/add-team-member"
            component={AddTeamMemberFormic}
          />
          <SecondaryLayoutRoute path="/team-member" component={TeamMember} />
          <SecondaryLayoutRoute
            path="/edit-team-member/:guid"
            component={EditTeamMemberFormic}
          />
          <SecondaryLayoutRoute path="/connect-list" component={ConnectList} />
          <SecondaryLayoutRoute
            path="/my-connections"
            component={MyConnections}
          />
          <SecondaryLayoutRoute
            path="/connections-for-team/:id"
            component={ConnectionsListForTeam}
          />
          <SecondaryLayoutRoute
            path="/invite-people"
            component={ReferFriendFormic}
          />

          <SecondaryLayoutRoute
            path="/team-work-invite-people/:Name/:ownerId/:ownerPhone"
            component={TeamInvitePeopleFormic}
          />

          <SecondaryLayoutRoute path="/reward-point" component={RewardsPoint} />
          <SecondaryLayoutRoute path="/payment" component={Payment} />
          <SecondaryLayoutRoute path="/my-ad" component={MyAdList} />
          <SecondaryLayoutRoute path="/dashboard" component={Dashboard} />
          <SecondaryLayoutRoute path="/add-shop" component={AddShopFormic} />
          <SecondaryLayoutRoute path="/add-team" component={AddTeamFormic} />
          <SecondaryLayoutRoute
            path="/edit-shop/:guid"
            component={EditShopFormic}
          />
          <SecondaryLayoutRoute
            path="/edit-team/:id"
            component={EditTeamFormic}
          />

          {/* <SecondaryLayoutRoute path="/profile/:link" component={ProfileLayout} /> */}

          <SecondaryLayoutRoute path="/shop-list" component={ShopList} />
          <SecondaryLayoutRoute path="/manage-shop" component={ManageShop} />
          <SecondaryLayoutRoute path="/manage-team" component={ManageTeam} />
          <SecondaryLayoutRoute path="/team-work" component={TeamWork} />
          <LoginLayoutRoute path="/login" component={LoginFormFormic} />
          <LoginLayoutRoute
            path="/create-account"
            component={RegisterByMobileFormic}
          />
          <LoginLayoutRoute
            path="/recovery"
            component={PasswordRecoveryFormic}
          />
          {/* <ShopLayoutRoute path="/shop/:link" component={ShopDetail} />
        <ShopLayoutRoute path="/profile/:link" component={PublicProfile} />
        <ShopLayoutRoute path="/my-shop" component={MyShopDetail}/> */}

          <DetailsPageLayoutRoute path="/ads/:guid" component={AdDetail} />

          {/* <Route
  path="/profile/:link"
  render={props => {
    const {
      match: {
        params: { link }
      }
    } = props;
    console.log('link from app.js',link)
    return (
    
      <ProfileLayout
        key={`link=${link}`}
        {...props}
      />
    );
  }}
/>; */}
          <Route path="/redirect-ad" component={RedirectAd} />
          <Route path="/redirect-page" component={RedirectMe} />
          <Route path="/redirect-payment" component={RedirectPayment} />
          <Route exact path="/:link" component={ProfileLayout} />
          <HomepageLayoutRoute path="/" component={MiddlePane} />
        </Switch>
      </React.Fragment>
    </MuiThemeProvider>
  );
});

export default App;
