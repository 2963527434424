import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { API_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import "./content.css";

const useStyles = (theme) => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    maxWidth: 700,
    maxHeight: 8000,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  flexZone: {
    display: "flex",
    justifyContent: "flex-end",
  },
  topLabel: {
    width: "400px",
    height: "40px",
    background: "#D1152D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightTopHalfCircle: {
    height: "40px",
    width: "20px",
    borderRadius: "90px 0px 0px 90px",
    background: "#D1152D",
  },
  bottomRightTriangle: {
    width: "30px",
    height: "30px",
    background: "#D1152D",
    borderRadius: `90px 0 0px 0`,
  },
});

const PrivacyPolicy = (props) => {
  const { classes } = props;
  return (
    <Paper className={classes.paper}>
      <div className={classes.flexContainer}>
        <div className={classes.topLabel}>
          <Typography variant="body1" color="secondary">
            Privacy Policy
          </Typography>{" "}
        </div>
        <div className={classes.rightTopHalfCircle}> </div>
      </div>

      <div style={{ margin: "10px" }} className="infoBody">
        <p>
          Welcome to <a href="https://prodeap.com">sundarbanX</a>!
        </p>
        <p>
          When you use <a href="http://prodeap.com">sundarbanX</a> you trust
          us with your information. This Privacy Policy is meant to help you to
          understand the data we collect, why we collect and what we do with.
          This is important; we hope you will take the time to read it
          carefully.
        </p>
        <p>
          There are different ways you can use our services &ndash; to create
          new advertisements, to create a new shop, search for advertisements,
          and share advertisements and many more upcoming features. We have
          tried to keep it as simple as possible. If you have any questions,
          please contact us.
        </p>
        <p>
          <strong>Information we collect:</strong>
        </p>
        <ul>
          <li>Mobile phone number</li>
          <li>IP address</li>
          <li>Contact details</li>
          <li>E-Mail address</li>
          <li>Page view statistics</li>
          <li>Computer sign-in details</li>
          <li>Standard web log information</li>
          <li>Website traffic</li>
        </ul>
        <p>
          We use the information we collect from all of our services to provide,
          maintain, protect and improve them, to develop new ones, and to
          protect <a href="http://prodeap.com">sundarbanX</a> and our users.
          We also use this information to offer you tailored content &ndash;
          like giving you relevant contents and ads.<strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Apps Permission:</strong>
        </p>
        <ul>
          <li>Camera Permission</li>
          <li>Write & Read of External Storage</li>
        </ul>
        <p>
          We use the information we collect from all of our services to provide,
          maintain, protect and improve them, to develop new ones, and to
          protect sundarbanX and our users. We also use this information to
          offer you tailored content – like giving you relevant contents and
          ads.
        </p>

        <p>
          <strong>How we use the information that we collect:</strong>
        </p>
        <ul>
          <li>Provide our services and get in touch with you when necessary</li>
          <li>Focus on safe business and enforce our policies</li>
          <li>Customize users experience, measure interest in our services</li>
          <li>
            Improve our services and inform users about services and updates
          </li>
          <li>
            Communicate marketing and promotional offers to you according to
            your preferences
          </li>
        </ul>
        <p>
          <strong>Disclosure:</strong>
        </p>
        <p>
          We never rent, sell any of your personal details to any third party
          for any kind of marketing needs. We do not disclose anything without
          your permission. If ever we disclose any of your personal information,
          it is only for legal requirements. We would like to let the users know
          that we prioritize everyone's rights, safety, and property. When you
          choose to impersonate someone's identity, we may disclose your
          personal information to some law &amp; order enforcement agency.
        </p>
        <p>
          We may not make full guarantees but we try our best to use the best
          tools in order to keep your personal data safe.
        </p>
      </div>

      <div className={classes.flexZone}>
        <div className={classes.bottomRightTriangle}> </div>
      </div>
      <ToastContainer autoClose={2000} />
    </Paper>
  );
};

export default withStyles(useStyles)(PrivacyPolicy);
