import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import LOGO from '../images/sundarbanX-logo.png';
import LOGO from '../images/sundarbanX.com-logo-beta.jpg';
import { Toolbar, Button, Link, AppBar, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PostAdDialog from './AD/PostAdDialog';
import AuthService from '../services/AuthService';
import { MoreVert } from '@material-ui/icons';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Avatar } from '@material-ui/core';
import { IMAGE_URL } from '../Constants';
//End logout dropdown

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  tr: {
    '&:hover': {
      color: '#d1152c',
      border: '1px solid #d1152c',
      background: 'rgba(209, 21, 44, 0.04)',
    },
  },
  trInvite: {
    background: '#d1152c',
    color: '#ffffff',
    '&:hover': {
      color: '#d1152c',
      border: '1px solid #d1152c',
      background: 'rgba(209, 21, 44, 0.04)',
    },
  },
  trMenu: {
    '&:hover': {
      color: '#d1152c',
      background: 'rgba(209, 21, 44, 0.04)',
    },
  },

  //   flexContainer: {
  //     display: 'flex',
  //     justifyContent: 'space-between'
  //   },
  //   topQuarterCircle:{
  //     width:'30px',
  //      height:'30px',
  //      background: '#D1152D',
  //      borderRadius: `0 0 90px 0`
  //   },
  //   halfCircleBottom:{
  //     height:'25px',
  //     width:'50px',
  //     borderRadius: '0 0 90px 90px',
  //     background: '#D1152D',
  //     marginRight: '243px'
  // },
  logo: {
    maxWidth: 240,
  },
}));

const NavBar = ({ authenticate, isAuthenticated }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);

  const owner = JSON.parse(localStorage.getItem('user'));

  const handleCreateShop = () => {
    if (!localStorage.getItem('user')) {
      history.push('/login', { redirectPath: '/add-shop' });
    } else {
      history.push('/add-shop');
    }
  };

  const handleReferFriend = () => {
    if (!localStorage.getItem('user')) {
      history.push('/login', { redirectPath: '/invite-people' });
    } else {
      history.push('/invite-people');
    }
  };
  const handleDialogOpen = () => {
    if (!localStorage.getItem('user')) {
      history.push('/login');
    }
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  // Logout dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goProfile = () => {
    history.push('/my-profile');
  };

  const handleLogout = () => {
    localStorage.clear();
    authenticate();
    handleClose();
    history.push('/');
  };
  // End Logout dropdown

  return (
    <div
      style={{
        position: 'fixed',
        backgroundColor: '#ffffff',
        width: '100%',
        margin: 'auto',
        zIndex: 1,
      }}
    >
      <AppBar style={{ backgroundColor: '#ffffff' }}>
        {/* <div className={classes.flexContainer}>
         <div className={classes.topQuarterCircle}>  </div>
        <div className={classes.halfCircleBottom}>  </div>
         </div> */}

        {/* <Toolbar style={{marginLeft: 160 }}> */}
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            <a href='/'>
              {/* <img onClick={()=>history.push('/')}  style={{cursor:'pointer' }} src={LOGO} alt="logo" className={classes.logo} /> */}
              <img
                style={{ cursor: 'pointer' }}
                src={LOGO}
                alt='logo'
                className={classes.logo}
              />
            </a>
          </Typography>

          {/* <Button style={{textTransform: "none"}}>About</Button>
          <Button style={{textTransform: "none"}}>Category</Button> */}

          <Button
            style={{ borderRadius: 25, marginRight: 20, textTransform: 'none' }}
            variant='outlined'
            onClick={handleDialogOpen}
            color='primary'
          >
            Create Advertisement
          </Button>

          <Button
            style={{ borderRadius: 25, marginRight: 20, textTransform: 'none' }}
            variant='outlined'
            onClick={handleCreateShop}
            className={classes.tr}
          >
            Create Shop
          </Button>
          {isAuthenticated ? (
            <Button
              style={{
                borderRadius: 25,
                marginRight: 15,
                textTransform: 'none',
              }}
              variant='outlined'
              onClick={handleReferFriend}
              className={classes.trInvite}
            >
              Invite People
            </Button>
          ) : (
            <Button
              style={{
                borderRadius: 25,
                marginRight: 15,
                textTransform: 'none',
              }}
              variant='outlined'
              onClick={handleReferFriend}
              className={classes.tr}
            >
              Invite People
            </Button>
          )}

          {isAuthenticated ? (
            // <Button onClick={()=>{localStorage.clear();authenticate()}} style={{ borderRadius: 25,marginRight: 15,textTransform: "none" }} variant="outlined">
            //   Logout
            //   <MoreVert></MoreVert>
            //   </Button>

            <div>
              <Button
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleClick}
                style={{
                  borderRadius: 25,
                  marginRight: 5,
                  padding: 3,
                  textTransform: 'none',
                }}
                variant='outlined'
                className={classes.tr}
              >
                <Avatar
                  style={{
                    height: '26px',
                    width: '26px',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                  }}
                  alt={owner.Name}
                  src={IMAGE_URL + owner.ProfilePicturePath}
                />
                <MoreVert style={{ paddingLeft: '3px' }}></MoreVert>
              </Button>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    goProfile();
                  }}
                  className={classes.trMenu}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleLogout();
                  }}
                  className={classes.trMenu}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <Button
              onClick={() => history.push('/login')}
              style={{ borderRadius: 25, textTransform: 'none' }}
              variant='contained'
              color='primary'
            >
              LogIn
            </Button>
          )}
        </Toolbar>

        <PostAdDialog
          isOpen={isOpen}
          handleClose={handleDialogClose}
          title='Create Advertisement'
        ></PostAdDialog>
      </AppBar>
    </div>
  );
};

export default NavBar;
