import React from 'react';
import { Formik } from 'formik';
import withStyles from '@material-ui/core/styles/withStyles';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import { API_URL } from '../../Constants';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import AddTeamMember from './AddTeamMember';

const useStyles = (theme) => ({
  paper: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    maxHeight: 900,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  flexZone: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  topLabel: {
    width: '250px',
    height: '40px',
    background: '#D1152D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightTopHalfCircle: {
    height: '40px',
    width: '20px',
    borderRadius: '90px 0px 0px 90px',
    background: '#D1152D',
  },
  bottomRightTriangle: {
    width: '30px',
    height: '30px',
    background: '#D1152D',
    borderRadius: `90px 0 0px 0`,
  },
});

const AddTeamMemberFormic = (props) => {
  let userInfo = JSON.parse(localStorage.getItem('user'));
  // Form data submit
  const submit = (data, { resetForm }) => {
    let teamMember = {
      Name: data.Name,
      Phone: data.Phone,
      Password: data.Password,
      CreatedBy: userInfo.Id,
      OwnerId: userInfo.Id,
    };

    axios
      .post(API_URL + 'UserInfoes/AddMember', teamMember)
      .then((response) => {
        if (response.data.Meta.Status === 1) {
          toast.success('Team Member added Successfully');
          resetForm({});
        } else {
          toast.error('Something went wrong');
        }
      });
  };

  const validationSchema = Yup.object({
    Name: Yup.string('Enter a name').required('Name is required'),
    Phone: Yup.string('Enter your Mobile Number')
      .required('Mobile Number is required')
      .matches(
        /^(01[3456789])(\d{8})$/,
        'Please Enter valid Bangladeshi Phone Number'
      ),
    Password: Yup.string('Enter a password').required('Password is required'),
  });

  const values = {
    Name: '',
    Phone: '',
    Password: '',
  };

  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexContainer}>
        <div className={classes.topLabel}>
          <Typography variant='body1' color='secondary'>
            Add New Team Member
          </Typography>{' '}
        </div>
        <div className={classes.rightTopHalfCircle}> </div>
      </div>

      <Formik
        render={(props) => <AddTeamMember {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      />
      <div className={classes.flexZone}>
        <div className={classes.bottomRightTriangle}> </div>
      </div>
      <ToastContainer autoClose={2000} />
    </Paper>
  );
};

export default withStyles(useStyles)(AddTeamMemberFormic);
