import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
// import ProfilePic from '../../images/Avatar1.jpg';
import axios from "axios";
import { API_URL, IMAGE_URL } from "../../Constants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    maxWidth: 700,
    maxHeight: 900,
  },
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  flexZone: {
    display: "flex",
    justifyContent: "flex-end",
  },
  topLabel: {
    width: "250px",
    height: "40px",
    background: "#D1152D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightTopHalfCircle: {
    height: "40px",
    width: "20px",
    borderRadius: "90px 0px 0px 90px",
    background: "#D1152D",
  },
  bottomRightTriangle: {
    width: "30px",
    height: "30px",
    background: "#D1152D",
    borderRadius: `90px 0 0px 0`,
  },
}));

const MyProfile = (props) => {
  const classes = useStyles();
  const history = useHistory();

  let [userInfo, setUserInfo] = React.useState({});
  let [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    axios
      .get(API_URL + "UserInfoes/DetailsByGUID?guid=" + user.GUID)
      .then((response) => {
        setUserInfo(response.data.Data);
        setIsLoading(false);
        console.log(
          "user info: " + response.data.Data.ProfilePicturePath,
          user.ProfilePicturePath
        );
        if (response.data.Data.ProfilePicturePath !== user.ProfilePicturePath) {
          localStorage.setItem("user", JSON.stringify(response.data.Data));
          setTimeout(() => {
            history.push("/");
            history.push("/my-profile");
          }, 200);
        }
      });
  }, []);

  if (!isLoading) {
    return (
      <div>
        <Paper className={classes.root}>
          <div className={classes.flexContainer}>
            <div className={classes.topLabel}>
              <Typography variant="body1" color="secondary">
                My Profile
              </Typography>{" "}
            </div>
            <div className={classes.rightTopHalfCircle}> </div>
          </div>
          <div style={{ paddingLeft: "30px" }}>
            {/* <Avatar alt="Travis Howard" src={Base_Image_URL+'Profile/'+item.CollectionImage} /> */}
            <Avatar
              style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }}
              alt={userInfo.Name}
              src={IMAGE_URL + userInfo.ProfilePicturePath}
              className={classes.large}
            />
            <Typography variant="subtitle2" style={{ paddingTop: "20px" }}>
              Name: {userInfo.Name}
            </Typography>
            <Typography variant="subtitle2">Email: {userInfo.Email}</Typography>
            <Typography variant="subtitle2">Phone: {userInfo.Phone}</Typography>
            <Typography variant="subtitle2">
              Bkash Number: {userInfo.BkashNumber}
            </Typography>
            <Typography variant="subtitle2">
              Address: {userInfo.Address}
            </Typography>
            <Button
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              style={{
                borderRadius: 25,
                textTransform: "none",
                padding: "9px 25px",
                marginTop: "15px",
              }}
              onClick={() => {
                history.push("/edit-profile/" + userInfo.GUID);
              }}
            >
              Edit Profile
            </Button>

            <Button
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              style={{
                borderRadius: 25,
                textTransform: "none",
                padding: "9px 25px",
                marginTop: "15px",
                marginLeft: "20px",
              }}
              onClick={() => {
                history.push("/" + userInfo.SeoLink);
              }}
            >
              View Profile
            </Button>
          </div>

          <div className={classes.flexZone}>
            <div className={classes.bottomRightTriangle}> </div>
          </div>
        </Paper>
      </div>
    );
  } else {
    return null;
  }
};

export default withStyles(useStyles)(MyProfile);
