import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { API_URL, BASEIMAGE } from "../../Constants";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  textFieldInput: {
    width: "300px",
  },
  uploadField: {
    position: "relative",
    display: "inline-flex",
    background: "#ffffff",
  },
  btnUpload: {
    position: "absolute",
    top: 0,
    right: 0,
    borderRadius: 25,
    textTransform: "none",
    padding: "9px 35px",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: "10px 0",
  },
}));
const AddTeam = (props) => {
  const classes = useStyles();
  const {
    values: { Name, Email, Phone, Description },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
  } = props;

  React.useEffect(() => {
    axios.get(API_URL + "ShopTypes").then((response) => {
      console.log(response);
      //   setShopTypes(response.data);
    });
  }, []);

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant="outlined"
        id="Name"
        name="Name"
        helperText={touched.Name ? errors.Name : ""}
        error={touched.Name && Boolean(errors.Name)}
        label="Name"
        value={Name}
        onChange={change.bind(null, "Name")}
        size="small"
        className={classes.marginBottom}
      />

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant="outlined"
        id="Email"
        name="Email"
        helperText={touched.Email ? errors.Email : ""}
        error={touched.Email && Boolean(errors.Email)}
        label="Email (Optional)"
        value={Email || ""}
        onChange={change.bind(null, "Email")}
        size="small"
        className={classes.marginBottom}
      />

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant="outlined"
        id="Phone"
        name="Phone"
        helperText={touched.Phone ? errors.Phone : ""}
        error={touched.Phone && Boolean(errors.Phone)}
        label="Phone Number"
        value={Phone || ""}
        onChange={change.bind(null, "Phone")}
        size="small"
        className={classes.marginBottom}
      />

      <TextField
        multiline
        rows="4"
        rowsMax="50"
        variant="outlined"
        fullWidth
        size="small"
        helperText={touched.Description ? errors.Description : ""}
        error={errors.Description && Boolean(errors.Description)}
        id="Description"
        value={Description ? Description.replaceAll("<br />", "\n") : ""}
        label="Description"
        name="Description"
        onChange={change.bind(null, "Description")}
        className={classes.marginBottom}
        style={{ width: "300px" }}
      />

      <Button
        type="submit"
        size="small"
        variant="contained"
        color="primary"
        className={classes.marginBottom}
        disabled={!isValid}
        style={{ borderRadius: 25, textTransform: "none", padding: "9px 25px" }}
      >
        Create Team
      </Button>
      <ToastContainer autoClose={2000} />
    </form>
  );
};

export default AddTeam;
