import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_URL, IMAGE_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexContainer1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: 45,
    background: "#FBFBFB",
    marginBottom: "20px",
  },
  flexContainer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    margin: `0 12px 12px 12px`,
  },

  halfCircle1: {
    width: "50px",
    height: "35px",
    background: "#D1152D",
    borderRadius: `90px 90px 0 0`,
    marginLeft: "10px",
  },
  halfCircle2: {
    width: "50px",
    height: "25px",
    background: "#D1152D",
    borderRadius: `90px 90px 0 0`,
    marginRight: "15px",
  },
  title2: {
    marginBottom: "10px",
  },
  marginBottom: {
    marginBottom: "20px",
  },
  large: {
    width: 50,
    height: 50,
    marginLeft: "10px",
  },
  showAllBtn: {
    borderRadius: 25,
    textTransform: "none",
    padding: "6px 30px",
    marginTop: "10px",
    marginBottom: "15px",
  },
  hoverBtn: {
    borderRadius: 25,
    textTransform: "none",
    "&:hover": {
      background: "#D1152C",
      color: "#ffffff",
    },
  },
}));

const ActiveList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  let [loading, setLoading] = React.useState(true);

  let [activeList, setActiveList] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(
        API_URL +
          "UserConnections/ActiveList?userid=" +
          JSON.parse(localStorage.getItem("user"))?.Id +
          "&pageSize=10"
      )
      .then((response) => {
        console.log("ActiveList: " + JSON.stringify(response));
        setActiveList(response.data);
        setLoading(false);
      });
  }, []);

  const [hasMore, setHasMore] = React.useState(true);
  let [page, setPage] = React.useState(0);
  const fetchMoreData = () => {
    page++;
    setPage(page);
    axios
      .get(
        API_URL +
          "UserConnections/ActiveList?userid=" +
          JSON.parse(localStorage.getItem("user"))?.Id +
          "&pageIndex=" +
          page +
          "&pageSize=10"
      )
      .then((response) => {
        if (response.data.length < 1) setHasMore(false);
        setActiveList(activeList.concat(response.data));
      });
  };
  return loading ? (
    <Typography variant="body2">
      <h4>Loading...</h4>
    </Typography>
  ) : !loading && activeList.length === 0 ? (
    <p style={{ textAlign: "center", color: "#d1152c" }}>
      No Connections Available
    </p>
  ) : (
    <InfiniteScroll
      dataLength={activeList.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<p>loading</p>}
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {activeList.map((item, i) => (
        <Grid container justify="space-around" style={{ paddingBottom: 10 }}>
          <Grid item md={2} className={classes.container}>
            <Avatar
              style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }}
              variant="circle"
              alt={item.ConnectedWithUserName}
              src={IMAGE_URL + item.ConnectedWithUserProfilePicturePath}
              className={classes.large}
            />
          </Grid>
          <Grid item md={2} className={classes.container}>
            <Typography variant="subtitle2">
              {item.ConnectedWithUserName}
            </Typography>
          </Grid>
          {/* <Grid item md={2} className={classes.container}>
            <Typography variant="subtitle2">
              {item.ConnectedWithUserPhone}
            </Typography>
          </Grid> */}
          <Grid item md={3} className={classes.container}>
            <Typography variant="subtitle2">
              {item.ConnectedWithUserCity}, {item.ConnectedWithUserState}
            </Typography>
          </Grid>
          {props.isTeam && (
            <Grid item md={2} className={classes.container}>
              <Button
                onClick={(e) => props.addPeople(item.Id)}
                className={classes.hoverBtn}
                size="small"
                variant="outlined"
              >
                Add
              </Button>
            </Grid>
          )}
          {/* <Grid item md={4}>
             {item.AcceptedStatus==true?<Chip color="primary" label="accepted" />:null}
          </Grid> */}
        </Grid>
      ))}
      <ToastContainer autoClose={2000} />
    </InfiniteScroll>
  );
};

export default ActiveList;
