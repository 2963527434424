import React from "react";
import { useState } from "react";
import "./tagStyle.css";
import theme from "./theme.css";

// Auto-Suggestion
import Autosuggest from "react-autosuggest";

export default function MyTag(props) {
  // Auto-Suggestion
  const suggestionsTag = props.suggestionsTag;

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : suggestionsTag.filter(
          (sTag) => sTag.name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;
  // Auto-Suggestion

  //   const {};
  const [tags, setTags] = useState(props.Tags);

  let tagInput = { value: null };

  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);

    // send data to parent component
    props.onUpdateMyTag(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setTags([...tags, val]);

      // send data to parent component
      props.onUpdateMyTag([...tags, val]);

      // Clear the field for next input
      tagInput.value = null;

      // Auto-Suggestion
      setSuggestions([]);
      setValue("");
    } else if (e.key === "Backspace" && !val) {
      // removeTag(tags.length - 1);
    }
  };

  // Auto-Suggestion
  const [value, setValue] = React.useState("");
  const [suggestions, setSuggestions] = React.useState([]);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: "Add your Tag",
    value,
    onChange: onChange,

    onKeyDown: inputKeyDown,
  };
  // Auto-Suggestion

  return (
    <div>
      {tags.length > 0 && (
        <div className="input-tag" style={{ marginBottom: 15 }}>
          <ul className="input-tag__tags">
            {tags.map((tag, i) => (
              <li key={tag}>
                {tag}
                <button
                  type="button"
                  onClick={() => {
                    removeTag(i);
                  }}
                >
                  +
                </button>
              </li>
            ))}
            <li
              className="input-tag__tags__input"
              style={{ minHeight: 25 }}
            ></li>
          </ul>
        </div>
      )}

      {/* for testing */}
      {/* <div>My1: {tags}</div> */}

      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </div>
  );
}
