import { Avatar, Button, Paper, Typography } from "@material-ui/core";
import React from "react";
import AddReply from "./AddReply";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import SingleReply from "./SingleReply";
import { IMAGE_URL, BASE_URL } from "./../../Constants";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  bgComment: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 25,
    padding: "10px",
    maxWidth: "435px",
  },
}));
const SingleComment = (props) => {
  const classes = useStyles();
  const { comment, noOfReplies, guid } = props;
  let [displayReplyBox, setDisplayReplyBox] = React.useState(false);
  let [displayReplyList, setDisplayReplyList] = React.useState(false);

  const history = useHistory();

  const handleReply = () => {
    if (localStorage.getItem("user") === null) {
      guid
        ? history.push("/login", {
            redirectPath: "/ads/" + guid,
          })
        : history.push("/login");
    } else {
      setDisplayReplyBox(!displayReplyBox);
    }
  };

  const handleReplyList = () => setDisplayReplyList(!displayReplyList);

  console.log("commentM: ", JSON.parse(localStorage.getItem("user")), comment);
  return (
    <Paper elevation={0} className={classes.root}>
      <div style={{ paddingLeft: "15px" }}>
        <a href={comment.userProfileLink} target="_blank">
          <Avatar
            style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }}
            alt={comment.CommentedByName}
            src={BASE_URL + comment.CommentedByProfilePic}
          />
        </a>
      </div>
      <div style={{ paddingLeft: "15px" }}>
        <div className={classes.bgComment}>
          <Typography variant="subtitle1" color="textPrimary">
            {comment.CommentedByName}
          </Typography>

          <Typography
            style={{ wordBreak: "break-all" }}
            color="textPrimary"
            variant="body2"
          >
            {comment.Comment}
          </Typography>
        </div>
        <Link
          style={{ paddingLeft: "10px", color: "#D1152C", cursor: "pointer" }}
          onClick={handleReply}
        >
          {" "}
          reply{" "}
        </Link>
        {displayReplyBox ? <AddReply commentId={comment.Id} /> : null}
        {noOfReplies === 0 ? null : (
          <Link
            style={{ paddingLeft: "10px", cursor: "pointer" }}
            onClick={handleReplyList}
          >
            {" "}
            view {noOfReplies} reply{" "}
          </Link>
        )}

        {displayReplyList
          ? comment.CommentReplyList.map((r, i) => (
              <SingleReply reply={r} key={i} />
            ))
          : null}
      </div>
    </Paper>
  );
};

export default SingleComment;
