import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Link from '@material-ui/core/Link';
// import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
// import Container from '@material-ui/core/Container';
import axios from "axios";
import { API_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Paper } from "@material-ui/core";
// import { useParams } from 'react-router-dom';
import ReferFriend from "./ReferFriend";

const useStyles = (theme) => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    maxWidth: 700,
    maxHeight: 900,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  flexZone: {
    display: "flex",
    justifyContent: "flex-end",
  },
  topLabel: {
    width: "400px",
    height: "40px",
    background: "#D1152D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightTopHalfCircle: {
    height: "40px",
    width: "20px",
    borderRadius: "90px 0px 0px 90px",
    background: "#D1152D",
  },
  bottomRightTriangle: {
    width: "30px",
    height: "30px",
    background: "#D1152D",
    borderRadius: `90px 0 0px 0`,
  },
});

const ReferFriendFormic = (props) => {
  const submit = (data, { resetForm }) => {
    let userInfo = JSON.parse(localStorage.getItem("user"));
    let referedInfo = {
      Name: data.Name,
      Phone: data.Phone,
      Email: data.Email,
      CreatedBy: userInfo.Id,
      ReferredBy: userInfo.Id,
      ReferredByMobile: userInfo.Phone,
    };

    console.log(referedInfo);

    axios.post(API_URL + "UserInfoes/Refer", referedInfo).then((res) => {
      console.log("invite people : " + JSON.stringify(res));
      if (res.data.Meta.Status === 1) {
        toast.success(res.data.Meta.Message);
        resetForm({});
      } else {
        toast.error(res.data.Meta.Message);
      }
    });
  };

  const validationSchema = Yup.object({
    Name: Yup.string("Enter a name").required("Name is required"),
    Phone: Yup.string("Enter a Phone Number")
      .required("Phone Number is required")
      .matches(
        /^(01[3456789])(\d{8})$/,
        "Please Enter valid Bangladeshi Phone Number"
      ),
    // StateId: Yup.number("Select District")
    // .required("District is required"),
    // CityId: Yup.number("Select Thana")
    // .required("Thana is required"),
  });

  const values = { Name: "", Phone: "", Email: "", CreatedBy: 1 };

  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexContainer}>
        <div className={classes.topLabel}>
          <Typography variant="body1" color="secondary">
            Invite Your Friend to prodeap.com
          </Typography>{" "}
        </div>
        <div className={classes.rightTopHalfCircle}> </div>
      </div>

      <Formik
        enableReinitialize
        render={(props) => <ReferFriend {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      />
      <div className={classes.flexZone}>
        <div className={classes.bottomRightTriangle}> </div>
      </div>
      <ToastContainer autoClose={2000} />
    </Paper>
  );
};

export default withStyles(useStyles)(ReferFriendFormic);
