import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_URL, IMAGE_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Paper, Tab, Tabs } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexContainer1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: 45,
    background: "#FBFBFB",
    marginBottom: "20px",
  },
  flexContainer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    margin: `0 12px 12px 12px`,
  },

  halfCircle1: {
    width: "50px",
    height: "35px",
    background: "#D1152D",
    borderRadius: `90px 90px 0 0`,
    marginLeft: "10px",
  },
  halfCircle2: {
    width: "50px",
    height: "25px",
    background: "#D1152D",
    borderRadius: `90px 90px 0 0`,
    marginRight: "15px",
  },
  title2: {
    marginBottom: "10px",
  },
  marginBottom: {
    marginBottom: "20px",
  },
  large: {
    width: 50,
    height: 50,
    marginLeft: "10px",
  },
  showAllBtn: {
    borderRadius: 25,
    textTransform: "none",
    padding: "6px 30px",
    marginTop: "10px",
    marginBottom: "15px",
  },
  hoverBtn: {
    borderRadius: 25,
    textTransform: "none",
    "&:hover": {
      background: "#D1152C",
      color: "#ffffff",
    },
  },
  staticBtn: {
    borderRadius: 25,
    textTransform: "none",
    pointerEvents: "none",
  },
}));

const TeamConnectionsList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  let [loading, setLoading] = React.useState(true);

  let [activeList, setActiveList] = React.useState([]);

  //Search
  const [value, setValue] = React.useState("");

  const [loadAgain, setLoadAgain] = React.useState("");

  const url =
    "UserInfoes/TeamConnectList?userId=" +
    JSON.parse(localStorage.getItem("user"))?.Id +
    "&teamId=" +
    props.teamId;

  React.useEffect(() => {
    axios.get(API_URL + url).then((response) => {
      console.log("Team C: " + JSON.stringify(response));
      setActiveList(response.data);
      setLoading(false);
    });
  }, [loadAgain]);

  const [hasMore, setHasMore] = React.useState(true);
  let [page, setPage] = React.useState(0);

  const fetchMoreData = () => {
    page++;
    setPage(page);
    axios
      .get(API_URL + url + "&pageIndex=" + page + "&pageSize=10")
      .then((response) => {
        if (response.data.length < 1) setHasMore(false);
        setActiveList(activeList.concat(response.data));
      });
  };

  const takeAction = (item, actionName) => {
    actionName === "Add" ? props.addPeople(item) : props.removePeople(item);
    setTimeout(() => {
      loadAgain === "load" ? setLoadAgain("") : setLoadAgain("load");
    }, 1000);
  };

  return loading ? (
    <Typography variant="body2">
      <h4>Loading...</h4>
    </Typography>
  ) : !loading && activeList.length === 0 ? (
    <p style={{ textAlign: "center", color: "#d1152c" }}>
      No Connections Available
    </p>
  ) : (
    <InfiniteScroll
      dataLength={activeList.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<p>loading</p>}
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      <Paper
        style={{
          flexGrow: 1,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 20,
          paddingRight: 20,
          marginBottom: 10,
        }}
      >
        <Grid item md={12} className={classes.container}>
          <Typography color="primary">Connections</Typography>
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search.."
            style={{
              height: 30,
              width: 250,
              borderRadius: 20,
              paddingLeft: 10,
              border: "1px solid rgba(0, 0, 0, 0.23)",
            }}
          />
        </Grid>
      </Paper>

      {activeList
        // Search option, check item by filter is a search query or for all data
        .filter((item) => {
          let checkItem = item.ConnectedWithUserName.toLowerCase();
          if (!value) return true;
          if (
            checkItem.includes(value.toLowerCase()) ||
            item.ConnectedWithUserCity.includes(value)
          ) {
            return true;
          }
          return false;
        })
        // Show item list
        .map((item, i) => (
          <Grid container justify="space-around" style={{ paddingBottom: 10 }}>
            <Grid item md={2} className={classes.container}>
              <Avatar
                style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }}
                variant="circle"
                alt={item.ConnectedWithUserName}
                src={IMAGE_URL + item.ConnectedWithUserProfilePicturePath}
                className={classes.large}
              />
            </Grid>
            <Grid item md={4} className={classes.container}>
              <Typography variant="subtitle2">
                {item.ConnectedWithUserName}
              </Typography>
            </Grid>
            {/* <Grid item md={2} className={classes.container}>
            <Typography variant="subtitle2">
              {item.ConnectedWithUserPhone}
            </Typography>
          </Grid> */}
            {/* <Grid item md={3} className={classes.container}>
              <Typography variant="subtitle2">
                {item.ConnectedWithUserCity}, {item.ConnectedWithUserState}
              </Typography>
            </Grid> */}

            <Grid item md={2} className={classes.container}>
              {!item.TeamActiveStatus ? (
                <Button
                  onClick={(e) => takeAction(item, "Add")}
                  className={classes.hoverBtn}
                  size="small"
                  variant="outlined"
                >
                  Add
                </Button>
              ) : (
                <Button
                  className={classes.staticBtn}
                  size="small"
                  variant="outlined"
                >
                  Added
                </Button>
              )}
            </Grid>

            <Grid item md={2} className={classes.container}>
              {item.TeamActiveStatus ? (
                <Button
                  onClick={(e) => takeAction(item, "Remove")}
                  className={classes.hoverBtn}
                  size="small"
                  variant="outlined"
                >
                  Remove
                </Button>
              ) : // <Button
              //   style={{}}
              //   size="small"
              //   variant="outlined"
              // >
              //   Remove
              // </Button>
              null}
            </Grid>

            {/* <Grid item md={4}>
             {item.AcceptedStatus==true?<Chip color="primary" label="accepted" />:null}
          </Grid> */}
          </Grid>
        ))}
      <ToastContainer autoClose={2000} />
    </InfiniteScroll>
  );
};

export default TeamConnectionsList;
