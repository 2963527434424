import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import OtpInput from "react-otp-input";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  marginAll: {
    marginBottom: theme.spacing(3),
  },
  textFieldInput: {
    width: "300px",
  },
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "30%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const VerificationCode = (props) => {
  const classes = useStyles();
  const history = useHistory();
  let {
    // values: { verificationPin },
    // errors,
    // touched,
    // handleSubmit,
    // handleChange,
    // isValid,
    // setFieldTouched,
    onSubmit,
    vPin,
    mCode,
    loading,
  } = props;

  //Otp box mode
  let [otps, setOtp] = React.useState({ otp: "" });
  let [isDisable, setIsDisable] = React.useState(true);
  let [vCode, setVCode] = React.useState(vPin);

  let updatePin = (otp) => {
    setOtp({ otp });
    setVCode(otp);
    console.log("m o t: " + otp, otps);

    // Make submit button active and disable
    if (otp.length === 4) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  };

  const submitMF = (e) => {
    if (vCode !== mCode) {
      e.preventDefault();
    }
    onSubmit(vCode);
  };
  // OTP box mode end

  // const change = (name, e) => {
  //   console.log('m e: ' + name, e);

  //   // e.persist();
  //   handleChange(e);

  //   setFieldTouched(name, true, false);
  // };

  return (
    <form onSubmit={submitMF} className={classes.root}>
      <Typography style={{ marginLeft: "20px", marginBottom: "10px" }}>
        {" "}
        Enter the Pin that you have received via SMS
      </Typography>
      {/* <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            variant="outlined"
            id="verificationPin"
            name="verificationPin"
            // helperText={touched.verificationPin ? errors.verificationPin : ""}
            // error={touched.verificationPin && Boolean(errors.verificationPin)}
            label="Verification Pin"
            value={vCode}
            onChange={change.bind(null, "verificationPin")}
            size="small"
            className={classes.marginAll}
            
          /> */}

      <OtpInput
        numInputs={4}
        separator={<span>-</span>}
        id="verificationPin1"
        name="verificationPin1"
        value={otps.otp}
        onChange={updatePin}
        inputStyle={{ width: 30, height: 30, fontSize: 18 }}
        className={classes.marginAll}
      />

      <div className="flex flex-wrap items-center mb-4">
        <div className={classes.wrapper}>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            className={classes.marginAll}
            disabled={isDisable || loading}
            style={{
              borderRadius: 25,
              textTransform: "none",
              padding: "9px 50px",
            }}
          >
            Verify Pin
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>

      <Link
        style={{ cursor: "pointer", marginBottom: "15px" }}
        onClick={() => history.push("/login")}
        variant="body2"
      >
        Try to Log In
      </Link>
    </form>
  );
};

export default VerificationCode;
