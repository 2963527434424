import React from 'react';
import { Formik } from 'formik';
import withStyles from '@material-ui/core/styles/withStyles';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { API_URL } from '../../Constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Paper } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import EditTeamMember from './EditTeamMember';

const useStyles = (theme) => ({
  paper: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    maxHeight: 1200,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  flexZone: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  topLabel: {
    width: '250px',
    height: '40px',
    background: '#D1152D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightTopHalfCircle: {
    height: '40px',
    width: '20px',
    borderRadius: '90px 0px 0px 90px',
    background: '#D1152D',
  },
  bottomRightTriangle: {
    width: '30px',
    height: '30px',
    background: '#D1152D',
    borderRadius: `90px 0 0px 0`,
  },
});

const EditTeamMemberFormic = (props) => {
  let { guid } = useParams();
  const history = useHistory();
  let [userInfo, setUserInfo] = React.useState({});
  const owner = JSON.parse(localStorage.getItem('user'));

  React.useEffect(() => {
    axios
      .get(API_URL + 'UserInfoes/DetailsByGUID?guid=' + guid)
      .then((response) => {
        console.log('t m: ' + JSON.stringify(response.data));
        setUserInfo(response.data.Data);
      });
  }, []);

  const submit = (data) => {
    console.log('on Submit: ' + JSON.stringify(data));
    userInfo.Name = data.Name;
    userInfo.Phone = data.Phone;
    if (data.Password !== '') {
      userInfo.Password = data.Password;
    }
    userInfo.UpdatedBy = owner.Id;
    console.log('on Submit: ' + JSON.stringify(userInfo));

    // Info update api
    axios
      .put(API_URL + 'UserInfoes?id=' + userInfo.Id, userInfo)
      .then((res) => {
        console.log('p u: ' + JSON.stringify(userInfo));
        if (res.data.Meta.Status === 1) {
          toast.success(res.data.Meta.Message);
          setTimeout(() => {
            history.push('/team-member');
          }, 2000);
        } else {
          toast.error('Something Went Wrong');
        }
      });
  }; // end submit function

  const validationSchema = Yup.object({
    Name: Yup.string('Enter a name').required('Name is required'),
    Phone: Yup.string('Enter your Mobile Number')
      .required('Mobile Number is required')
      .matches(
        /^(01[3456789])(\d{8})$/,
        'Please Enter valid Bangladeshi Phone Number'
      ),
    Password: Yup.string('Enter a password').required('Password is required'),
  });

  const values = {
    Name: userInfo.Name,
    Phone: userInfo.Phone,
    Password: userInfo.Password,
  };

  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexContainer}>
        <div className={classes.topLabel}>
          <Typography variant='body1' color='secondary'>
            Update Team Member
          </Typography>{' '}
        </div>
        <div className={classes.rightTopHalfCircle}> </div>
      </div>

      <Formik
        enableReinitialize
        render={(props) => <EditTeamMember {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      />
      <div className={classes.flexZone}>
        <div className={classes.bottomRightTriangle}> </div>
      </div>
      <ToastContainer autoClose={2000} />
    </Paper>
  );
};

export default withStyles(useStyles)(EditTeamMemberFormic);
