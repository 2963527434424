import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid, Container, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import AdCard from './AD/AdCard';
import RightSideBar from './RightSideBar';
import TopPick from './home/TopPick';
import SearchProduct from './home/SearchProduct';
import PostYourAd from './home/PostYourAd';
import axios from 'axios';
import { API_URL } from '../Constants';
import SkeletonCard from '../layouts/SkeletonCard';
import Skeleton from 'react-loading-skeleton';

export default function MiddlePane() {
  const [value, setValue] = React.useState(0);
  const [items, setItems] = React.useState([]);
  const [routeText, setRouteText] = React.useState('AllDev');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    axios.get(API_URL + 'Products/AllDev?pageSize=10').then((response) => {
      console.log('card ad img: ' + JSON.stringify(response.data));
      setItems(response.data);
      setLoading(false);
      document.title =
        'prodeap.com - Business In Your Pocket | Classified Business Advertisement Network';
    });
  }, []);

  //infinitity Scroll start
  const [hasMore, setHasMore] = React.useState(true);
  let [page, setPage] = React.useState(0);

  const fetchMoreData = () => {
    console.log(routeText);
    if (routeText === 'AllDev') {
      page++;
      setPage(page);

      axios
        .get(
          API_URL +
            'Products/' +
            routeText +
            '?pageIndex=' +
            page +
            '&pageSize=10'
        )
        .then((response) => {
          setItems(items.concat(response.data));
        });
    }
  };
  //infinity Scroll end

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setLoading(true);
      setRouteText('AllDev');

      axios.get(API_URL + 'Products/AllDev?pageSize=10').then((response) => {
        console.log('All P: ' + response);
        setItems(response.data);
        setLoading(false);
      });
    }

    if (newValue === 1) {
      setLoading(true);
      setRouteText('TopPick');

      axios.get(API_URL + 'Products/TopPick?pageSize=10').then((response) => {
        setItems(response.data);
        console.log('Top Pro: ' + response.data);
        setLoading(false);
      });
    }

    setValue(newValue);
  };

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={
        <ul style={{ listStyleType: 'none' }} className='list'>
          {value === 0 &&
            Array(3)
              .fill()
              .map((item, index) => (
                <li className='card' key={index}>
                  <Skeleton width={`66%`} height={210} />
                  <h4 className='card-title'>
                    <Skeleton height={2} width={`66%`} />
                  </h4>
                </li>
              ))}
        </ul>
      }
      scrollableTarget='scrollableDiv'
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12}>
          {/* <Typography style={{marginBottom:'10px',marginLeft:'13px'}}>Search Your Product</Typography> */}
          {/* <SearchProduct/> */}

          <PostYourAd />

          <Paper style={{ flexGrow: 1, marginTop: 10 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              centered
            >
              <Tab
                style={{ textTransform: 'none' }}
                label='All Advertisement'
              />
              <Tab style={{ textTransform: 'none' }} label='Top Pick' />
            </Tabs>
          </Paper>

          {loading && <SkeletonCard />}
          {!loading &&
            items.map((i, index) => (
              <AdCard item={i} key={index} displayEditBtn={true} />
            ))}

          {value === 1 && (
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          )}
        </Grid>

        {/* <Grid item lg={4} md={4}>
          <RightSideBar />
        </Grid> */}
      </Grid>
    </InfiniteScroll>
  );
}
