import React from "react";
import {Link} from 'react-router-dom';
import {
  withStyles,
  
} from "@material-ui/core";
import ShopTopPick from './ShopTopPick'
import FeaturedProducts from "../home/FeaturedProducts";
import ShopFeaturedProducts from "./ShopFeaturedProducts";
import Footer from "../Footer";

function ShopRightSidebar({shopId}) {
  
  return (
    <div style={{marginTop:'10px'}}>  
         <ShopTopPick shopId={shopId}/>
        
         <ShopFeaturedProducts shopId={shopId}/>
         <Footer></Footer>
        
    </div>
  );
}



export default ShopRightSidebar;
