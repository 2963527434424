import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
      },
    },
  },
  marginAll: {
    marginBottom: theme.spacing(3),
  },
  borderRad: {
    borderRadius: "25px",
  },
  textFieldInput: {
    width: "300px",
  },
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "30%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const LoginForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    values: { LoginId, Password },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    loading,
  } = props;

  const height = 40;

  const change = (name, e) => {
    console.log(e);
    console.log(name);
    e.persist();
    handleChange(e);

    setFieldTouched(name, true, false);

    // Show hide part
    setValues({ ...values, [name]: e.target.value });
  };

  // Show hide password
  const [values, setValues] = React.useState({
    Password: "",
    showPassword: false,
  });

  const handleChangeN = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // Show hide password end

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant="outlined"
        id="LoginId"
        name="LoginId"
        helperText={touched.LoginId ? errors.LoginId : ""}
        error={touched.LoginId && Boolean(errors.LoginId)}
        label="Phone Number"
        value={LoginId}
        onChange={change.bind(null, "LoginId")}
        size="small"
        className={classes.marginAll}
      />

      {/* <CustomTextField
           InputProps={{
            classes: {
              root: classes.textFieldInput
            },
          }}
            variant="outlined"
            id="Password"
            name="Password"
            helperText={touched.Password ? errors.Password : ""}
            error={touched.Password && Boolean(errors.Password)}
            label="Password"
            type="Password"
            value={Password}
            onChange={change.bind(null, "Password")}
            size="small"
            
            className={classes.marginAll}
          /> */}

      <FormControl
        size="small"
        className={clsx(
          classes.margin,
          classes.textField,
          classes.marginAll,
          classes.borderRad,
          classes.textFieldInput
        )}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={values.showPassword ? "text" : "Password"}
          value={values.Password}
          onChange={change.bind(null, "Password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
          name="Password"
          helperText={touched.Password ? errors.Password : ""}
          error={touched.Password && Boolean(errors.Password)}
        />
      </FormControl>

      <div className="flex flex-wrap items-center mb-4">
        <div className={classes.wrapper}>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            className={classes.marginAll}
            disabled={!isValid || loading}
            style={{
              borderRadius: 25,
              textTransform: "none",
              padding: "9px 50px",
            }}
          >
            Login
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>

      <a href="/recovery">Forgot password?</a>
      <p
        variant="body2"
        style={{ marginBottom: "15px", color: "rgba(0, 0, 0, 0.87)" }}
      >
        {" "}
        Don't have an Account? <a href="/create-account">Create Account </a>
      </p>
    </form>
  );
};

export default LoginForm;
