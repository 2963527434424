import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IMG from '../../images/toppic.png';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, CardMedia, CardActions } from '@material-ui/core';
import { API_URL, BASEIMAGE, Base_Image_URL } from '../../Constants';
const useStyles = makeStyles((theme) => ({
  card: {
    maxHeight: 350,
    //marginBottom:20
  },
  rectangle: {
    height: '28px',
    background: '#D1152C',
    display: 'flex',
    justifyContent: 'center',
  },
  media: {
    height: 150,
    marginLeft: 15,
    marginRight: 15,
  },
  title: {
    fontSize: 15,
  },
}));

const ShopTopPick = ({ shopId }) => {
  console.log('stp: ' + shopId);

  const [loading, setLoading] = React.useState(false);
  let [topPicProduct, setTopPicProduct] = React.useState([]);
  React.useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + 'Products/ShopTopPick?shopId=' + shopId + '&pageSize=' + 1)
      .then((response) => {
        console.log(response);
        setTopPicProduct(response.data);
        setLoading(false);
      });
  }, []);
  const history = useHistory();
  const classes = useStyles();
  return (
    <div style={{ marginBottom: '10px' }}>
      <div
        className={classes.rectangle}
        style={{ borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
      >
        <Typography variant='h6' color='secondary'>
          Top Pick
        </Typography>
      </div>

      {!loading &&
        (topPicProduct?.length ? (
          topPicProduct?.map((product, i) => (
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  variant='body2'
                  className={classes.title}
                  color='primary'
                >
                  {product?.Title}
                </Typography>
              </CardContent>

              {product.PictureListView.length >= 1 ? (
                product.PictureListView[i]?.FilePath === '/Upload//' ? (
                  <a href={'/ads/' + product.GUID} target='_blank'>
                    <CardMedia
                      className={classes.media}
                      image={
                        Base_Image_URL +
                        'Product/Small/' +
                        product.PictureListView[0]?.Filename
                      }
                      title={product.Title}
                    />
                  </a>
                ) : (
                  <a href={'/ads/' + product.GUID} target='_blank'>
                    <CardMedia
                      className={classes.media}
                      image={
                        BASEIMAGE + product.PictureListView[0]?.FileLinkSmall
                      }
                      title={product.Title}
                    />
                  </a>
                )
              ) : (
                <CardMedia
                  className={classes.media}
                  image={IMG}
                  title='head phone'
                />
              )}

              <CardContent>
                <Typography variant='subtitle2'>
                  Asking Price: {product?.RegularPrice} Tk
                </Typography>
                {
                  //               product?.MarketPrice===0?null:
                  // <Typography  variant="subtitle2">Market Price: {product?.MarketPrice} Tk</Typography>
                }
              </CardContent>
              <CardActions>
                {/* <Button onClick={()=>{const win = window.open("/"+product.GUID, "_blank");
                                  win.focus();}} size="small" style={{ borderRadius: 25 ,textTransform: "none",margin:'auto'}} variant="outlined" color="primary">Check Details</Button> */}
              </CardActions>
            </Card>
          ))
        ) : (
          <p style={{ textAlign: 'center' }}>
            <b>You don't have any Top Pick</b>
          </p>
        ))}
    </div>
  );
};

export default ShopTopPick;
