

import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import axios from 'axios';
import { API_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import './content.css';



const useStyles = theme => ({
  paper: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
   // alignItems: 'center',
    maxWidth:700,
    maxHeight:900,
   
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom:'20px'
  },
  flexZone: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  topLabel:{
     width:'400px',
     height:'40px',
     background: '#D1152D',
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
  },
  rightTopHalfCircle:{
    height:'40px',
    width:'20px',
    borderRadius: '90px 0px 0px 90px',
    background: '#D1152D',
   
},
bottomRightTriangle:{
     width:'30px',
     height:'30px',
     background: '#D1152D',
     borderRadius: `90px 0 0px 0`
},

});
  
const About =(props)=> {
 
    const { classes } = props;
    return (
      <Paper className={classes.paper}>

      <div className={classes.flexContainer}>
      <div className={classes.topLabel}>
      <Typography variant="body1"  color="secondary">
      About sundarbanX
     </Typography>  </div>
     <div className={classes.rightTopHalfCircle}>  </div>
    </div>
   
    <div style={{margin: "10px"}} className="infoBody">
          <p>Welcome to <a href="http://prodeap.com">sundarbanX</a> !!!</p>
      <p><a href="https://prodeap.com"><strong>sundarbanX</strong></a><strong> - ব্যবসাতো পকেটেই (Business In Your Pocket)</strong></p>
      <p><strong>Mission:</strong> To organize each business and make it more data-driven &amp; successful.</p>
      <p><strong>Vision:</strong> To create a socio-economic opportunity and an impactful society of global humanity.</p>
      <p><strong>Values: </strong></p>
      <ol>
      <li>Focus On The Users</li>
      <li>Focus Social Impact</li>
      <li>Technology Democratization</li>
      </ol>
      <p><a href="http://prodeap.com">sundarbanX</a> connects every MSMEs business including other established businesses on one platform through a secured process. The service enables every person to promote his/her business as well as share thoughts.</p>
      <p><a href="http://prodeap.com">sundarbanX</a> highly focused on the &ldquo;<strong>Marginal Economic Development (MED)</strong>&rdquo; to bypass the Middlemen &amp; Syndicates. This service will be enabling the micro-market and empowers the MSMEs business with development, managing, and business growth around Bangladesh (currently) through technology.</p>
      <p>End of the day, due to the supply chain process in the marginal area, most of the benefit of the raw production deposited in the middleman-syndicates pocket. <a href="http://prodeap.com">sundarbanX</a> replaces that position to break this chain. Due to <a href="http://prodeap.com">sundarbanX</a> services, marginal people can access more pieces of information, more markets, and more products that one chooses the better alternatives at a better price. <a href="http://prodeap.com">sundarbanX</a> reduces inventory costs as well.</p>
      <p><a href="http://prodeap.com">sundarbanX</a> brings the business to a single point of trading-dealing. It is like a Bird's-Eye view of the entire virtual business ecosystem. This will help all the sectors across a value chain to benefit and grow.</p>
      <p>&nbsp;</p>
    </div>
          
      <div className={classes.flexZone}>
      
        <div className={classes.bottomRightTriangle}>  </div>
      </div>
       <ToastContainer autoClose={2000} />
      </Paper>
    );
  }

export default withStyles(useStyles)(About);
