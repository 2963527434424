
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import { API_URL } from '../../Constants';
import { Typography } from '@material-ui/core';
import Moment from 'react-moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton } from 'react-loading-skeleton';
import { Grid } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Eclair', 262, 16.0),
];

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default function ReferredFriendList() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
 
  let [referredFriendList,setReferredFriendList]=React.useState([]);
  let user=JSON.parse(localStorage.getItem('user'));
  const [hasMore, setHasMore] = React.useState(true);

  React.useEffect(()=>{
    setLoading(true)
    
    axios.get(API_URL+'UserInfoes/ReferredList?userId='+user.Id+'&pageSize=10')
    .then(response => {
      console.log(response)
      if(response.data.length < 10) setHasMore(false);
      setReferredFriendList(response.data)
      setLoading(false)
   });
   },[])

   //infinitity Scroll start 
  
  let [page, setPage] = React.useState(0);
  
  const fetchMoreData=()=>{
    console.log('has More');
      page++;
      setPage(page);
      axios.get(API_URL+'UserInfoes/ReferredList?userId='+user.Id+'&pageIndex='+page+'&pageSize=10')
      .then(response => {
        console.log('more: ' + JSON.stringify(response.data));
        if(response.data.length < 1) setHasMore(false);
        setReferredFriendList(referredFriendList.concat(response.data));
      });
  }
  //infinity Scroll end


  
   return (

    !loading &&
    referredFriendList.length === 0 ? <h5>No List Available</h5>:
    <InfiniteScroll
            dataLength={referredFriendList.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            
            
            {
              (referredFriendList.length === 0 && !loading)? 
              <Typography align="center" color="primary" variant="h6">No Invited People</Typography>:
              
              <TableContainer component={Paper} style={{marginTop:'10px'}}>
                
                
                <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="">Name</StyledTableCell>
                    <StyledTableCell align="">Phone</StyledTableCell>
                    <StyledTableCell align="right">Invited At</StyledTableCell>
                    <StyledTableCell align="">Status</StyledTableCell>
                   
                  </TableRow>
                </TableHead>
                <TableBody>
                  {referredFriendList.map((row,i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        {row.ReferredUserName}
                      </StyledTableCell>
                      <StyledTableCell align="">{row.ReferredUserPhone}</StyledTableCell>
                      <StyledTableCell align="right">
                        <Moment format="D MMM YYYY HH:mm A" add={{ hours: 6 }}>{row.ReferredAt}</Moment>
                      </StyledTableCell>
                      <StyledTableCell align="">{row.ReferredStatus}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
                
             
            </TableContainer>
              
          }

          </InfiniteScroll>
    
               


    
  );
}
