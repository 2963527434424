import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import LOGO from "../../images/sundarbanX-logo.png";
import { Formik } from "formik";
import * as Yup from "yup";
// import PostAdForm from "./PostAdForm";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import CancelPresentationTwoToneIcon from "@material-ui/icons/CancelPresentationTwoTone";

import { Dialog, Typography, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { API_URL } from "../../Constants";
import EditAdForm from "./EditAdForm";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  topSquare: {
    width: "60px",
    height: "60px",
    background: "#D1152D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  logo: {
    maxWidth: 160,
  },
  borderBottom: {
    borderBottom: "2px solid #D1152D",
  },
  triangleBottomRight: {
    width: 0,
    height: 0,
    borderRight: "150px solid red",
    borderTop: "40px solid transparent",
    borderLeft: "400px solid transparent",
    borderBottom: "60px solid red",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const EditAdDialog = ({ isOpen, handleClose, title, advertisementGuid }) => {
  const history = useHistory();
  let [isMessage, setIsMessage] = React.useState(false);
  let [myMessage, setMyMessage] = React.useState();
  let [myMessageColor, setMyMessageColor] = React.useState();

  let [advertisement, setAdvertisement] = React.useState({});
  let [categoryIds, setCategoryIds] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(API_URL + "Products/DetailsByGuId?guid=" + advertisementGuid)
      .then((response) => {
        console.log("e p :" + JSON.stringify(response.data));
        setAdvertisement(response.data.Data);
        setCategoryIds(
          response.data.Data.ProductCategoryTreeWithId.split("=>")
        );
      });
  }, []);

  const submit = (data, { resetForm }) => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log("t s2: " + data.IsNegotiable, data.IsPriceRange);

    let categoryId = 0;
    if (data.RootCategoryId) {
      categoryId = data.RootCategoryId;
    }
    if (data.SubCategoryId) {
      categoryId = data.SubCategoryId;
    }
    if (data.SubSubCategoryId) {
      categoryId = data.SubSubCategoryId;
    }

    advertisement.Title = data.Title;
    advertisement.RegularPrice = data.AskingPrice;
    advertisement.MarketPrice = data.MarketPrice;
    advertisement.AllImageIds = data.NewUploadedImageIds.newUploadedImageIds;
    advertisement.Description = data.Description.replace(/\n/g, "<br />");
    advertisement.Tags = data.Tags;
    advertisement.IsNegotiable = data.IsNegotiable;
    advertisement.IsPriceRange = data.IsPriceRange;
    advertisement.PriceFrom = data.PriceFrom;
    advertisement.PriceTo = data.PriceTo;
    advertisement.UsedStatus = data.UsedStatus;
    advertisement.UsedDuration = data.UsedDuration;
    advertisement.UsedDurationUnit = data.UsedDurationUnit;
    advertisement.ShopId = data.ShopId;
    advertisement.ProductCategoryId = categoryId;
    advertisement.Phone = user.Phone;
    advertisement.OwnerId = user.Id;
    advertisement.StateId = user.StateId;
    advertisement.CityId = user.CityId;
    advertisement.UpdatedBy = user.Id;
    advertisement.StateName = user.StateName;
    advertisement.CityName = user.CityName;

    advertisement.ActiveStatus = data.ActiveStatus;
    advertisement.IsTopByUser = data.IsTopByUser;
    advertisement.IsFeaturedByUser = data.IsFeaturedByUser;

    axios.put(API_URL + "Products", advertisement).then((response) => {
      console.log("post up: " + JSON.stringify(response));
      console.log("post up1: " + JSON.stringify(advertisement));
      if (response.data.Meta.Status === 1) {
        messaginForma("Post Updated Successfully", "green");

        setTimeout(() => {
          handleClose();
          history.push("/redirect-ad");
        }, 3000);
      } else {
        messaginForma("Something went wrong", "#d1152c");
        console.log("post e:" + response.data.Meta.Message);
      }
    });
  };

  let messaginForma = (myMes, myColor) => {
    setMyMessageColor(myColor);
    setIsMessage(true);
    setMyMessage(myMes);
    setTimeout(() => {
      setIsMessage(false);
      setMyMessage();
    }, 4000);
  };

  const validationSchema = Yup.object().shape({
    Title: Yup.string("Enter Post Title")
      .required("Title is Required")
      .min(10, "Title lenght must be min 10 Characters")
      .max(100, "Title lenght must be max 1000 Characters"),

    AskingPrice: Yup.number().when("Pricing", {
      is: (Pricing) => Pricing === "IsNegotiable" || Pricing === "isFixed",
      then: Yup.number()
        .required("Asking Price Is Required")
        .min(1, "You must enter Asking Price")
        .positive()
        .integer(),
    }),

    PriceFrom: Yup.number().when("Pricing", {
      is: (Pricing) => Pricing === "IsPriceRange",
      then: Yup.number()
        .required("Starting Price Is Required")
        .min(1, "You must enter Starting Price")
        .positive()
        .integer(),
    }),

    PriceTo: Yup.number().when("Pricing", {
      is: (Pricing) => Pricing === "IsPriceRange",
      then: Yup.number()
        .required("Ending Price Is Required")
        .min(1, "You must enter Ending Price")
        .positive()
        .integer(),
    }),

    UsedDuration: Yup.number().when("UsedStatus", {
      is: (UsedStatus) => UsedStatus === true,
      then: Yup.number()
        .required("Used Duration is Required")
        .min(1, "You must enter Duration")
        .positive()
        .integer(),
    }),

    RootCategoryId: Yup.string().required("You must select one Category."),
  });

  const values = {
    Id: advertisement.Id,
    Title: advertisement.Title,
    RootCategoryId: categoryIds[0],
    SubCategoryId: categoryIds[1],
    SubSubCategoryId: categoryIds[2],
    AskingPrice: advertisement.RegularPrice,
    MarketPrice: advertisement.MarketPrice,
    Description: advertisement.Description,
    Tags: advertisement.Tags,
    IsNegotiable: advertisement.IsNegotiable,
    IsPriceRange: advertisement.IsPriceRange,
    PriceFrom: advertisement.PriceFrom,
    PriceTo: advertisement.PriceTo,
    UsedStatus: advertisement.UsedStatus,
    UsedDuration: advertisement.UsedDuration,
    UsedDurationUnit: advertisement.UsedDurationUnit,
    ShopId: advertisement.ShopId,
    GUID: advertisement.GUID,
    ActiveStatus: advertisement.ActiveStatus,
    PictureList: advertisement.PictureListView,
    IsTopByUser: advertisement.IsTopByUser,
    IsFeaturedByUser: advertisement.IsFeaturedByUser,
  };
  const classes = useStyles();

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        scroll="body"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Grid container>
          <Grid item md={2} className={classes.borderBottom}>
            <div onClick={handleClose} className={classes.topSquare}>
              <ArrowBackIosOutlinedIcon color="secondary" />
            </div>
          </Grid>

          <Grid className={classes.borderBottom} item md={6}>
            <Typography style={{ paddingTop: 15 }} color="primary" variant="h5">
              {title}
            </Typography>
          </Grid>

          <Grid item md={3}>
            <img src={LOGO} alt="logo" className={classes.logo} />
          </Grid>
          <Grid item md={1}>
            <CancelPresentationTwoToneIcon
              onClick={handleClose}
              style={{ paddingLeft: 25 }}
            />
          </Grid>
        </Grid>

        {isMessage && (
          <p
            style={{
              color: myMessageColor,
              textAlign: "center",
            }}
          >
            {myMessage}
          </p>
        )}

        <Formik
          render={(props) => <EditAdForm {...props} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={submit}
          enableReinitialize
        />
        <ToastContainer autoClose={2000} />
      </Dialog>
    </>
  );
};

EditAdDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  advertisementGuid: PropTypes.string.isRequired,
};

export default EditAdDialog;
