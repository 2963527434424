import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
} from "@material-ui/core";
import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_URL } from "../../Constants";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },

  hoverBtn: {
    borderRadius: 25,
    textTransform: "none",
    "&:hover": {
      background: "#D1152C0D",
      //   color: "#ffffff",
      border: "1px solid #D1152C",
    },
  },
});

// Calculation
function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

// End Calculatio

export default function ProcessingPayment() {
  const classes = useStyles();

  let [points, setPoints] = React.useState([]);
  let [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios
      .get(
        API_URL +
          "/UserInfoes/PaymentProcessingList?userId=" +
          JSON.parse(localStorage.getItem("user")).Id
      )
      .then((res) => {
        setPoints(res.data);
        setLoading(false);
      });
  }, []);

  return (
    <TableContainer component={Paper} style={{ marginTop: "10px" }}>
      <p style={{ textAlign: "center" }}>Payment under Processing</p>
      {loading ? (
        <Typography variant="body2">
          <h4>Loading...</h4>
        </Typography>
      ) : (
        <>
          {points.length == 0 ? (
            <p style={{ textAlign: "center", color: "#d1152c" }}>
              No Processing Payment Available
            </p>
          ) : (
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Point Type</StyledTableCell>
                  <StyledTableCell align="right">Reward Points</StyledTableCell>
                  <StyledTableCell align="right">
                    Cash Amount (Tk.)
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {points.map((row) => (
                  <StyledTableRow key={row.ReferredByUserId}>
                    <StyledTableCell component="th" scope="row">
                      {row.PointType}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.RewardPoints}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {ccyFormat(row.CashAmount)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <TableRow>
                  <StyledTableCell colSpan={2}>Total</StyledTableCell>
                  <StyledTableCell align="right">
                    {ccyFormat(
                      points
                        .map(({ CashAmount }) => CashAmount)
                        .reduce((sum, i) => sum + i, 0)
                    )}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </>
      )}
    </TableContainer>
  );
}
