import React from "react";
import { Typography, Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { Card, CardContent, CardMedia, CardActions } from "@material-ui/core";
import PostAdDialog from "../AD/PostAdDialog";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
      },
    },
  },
})(TextField);
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
  rectangle: {
    height: "30px",
    background: "#D1152C",
    display: "flex",
    justifyContent: "space-around",
  },

  fabContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    background: "#FBFBFB",
    marginLeft: "15px",
  },
  halfCircleContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    background: "#FBFBFB",
  },
  halfCircle: {
    width: "50px",
    height: "25px",
    background: "#D1152D",
    borderRadius: `90px 90px 0 0`,
    marginRight: "30px",
  },
  textFieldInput: {
    // minWidth:'300px',
    // maxWidth:'630px',
    background: "#FFFFFF",
    //  width:'100%'
  },
}));

const PostYourAd = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleDialogOpen = () => {
    if (!localStorage.getItem("user")) {
      history.push("/login");
    }
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };
  return (
    <div className={classes.root}>
      <Paper
        className={classes.rectangle}
        style={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}
      >
        <Typography variant="h6" style={{ marginLeft: 10 }} color="secondary">
          Create Advertisement
        </Typography>
      </Paper>
      <Paper>
        <div className={classes.fabContainer} style={{ paddingRight: "50px" }}>
          <Fab
            onClick={handleDialogOpen}
            style={{ marginTop: 10 }}
            size="small"
            color="secondary"
            aria-label="add"
          >
            {" "}
            <AddIcon />{" "}
          </Fab>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <CustomTextField
            InputProps={{
              classes: {
                root: classes.textFieldInput,
              },
            }}
            variant="outlined"
            id="SearchText"
            name="SearchText"
            size="small"
            label="Create What you want to advertise"
            disabled
            onClick={handleDialogOpen}
            style={{ width: "95%" }}
          />
        </div>
        <div className={classes.halfCircleContainer}>
          <div className={classes.halfCircle}> </div>
        </div>
      </Paper>

      <PostAdDialog
        isOpen={isOpen}
        handleClose={handleDialogClose}
        title="Create Advertisement"
      ></PostAdDialog>
    </div>
  );
};

export default PostYourAd;
