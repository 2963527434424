import {
  Avatar,
  Button,
  Chip,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import ProfilePic from "../../images/avatar.jpg";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_URL, IMAGE_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import SendList from "./SendList";
import AcceptList from "./AcceptList";
import ActiveList from "./ActiveList";

const useStyles = makeStyles((theme) => ({
  flexContainer1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: 45,
    background: "#FBFBFB",
    marginBottom: "20px",
  },
  flexContainer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    margin: `0 12px 12px 12px`,
  },

  halfCircle1: {
    width: "50px",
    height: "35px",
    background: "#D1152D",
    borderRadius: `90px 90px 0 0`,
    marginLeft: "10px",
  },
  halfCircle2: {
    width: "50px",
    height: "25px",
    background: "#D1152D",
    borderRadius: `90px 90px 0 0`,
    marginRight: "15px",
  },
  title2: {
    marginBottom: "10px",
  },
  marginBottom: {
    marginBottom: "20px",
  },
  large: {
    width: 50,
    height: 50,
    marginLeft: "10px",
  },
  showAllBtn: {
    borderRadius: 25,
    textTransform: "none",
    padding: "6px 30px",
    marginTop: "10px",
    marginBottom: "15px",
  },
  hoverBtn: {
    borderRadius: 25,
    textTransform: "none",
    "&:hover": {
      background: "#D1152C",
      color: "#ffffff",
    },
  },
}));

const MyConnections = (props) => {
  const classes = useStyles();
  let [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.marginBottom}>
      <Paper style={{ flexGrow: 1, margin: "10px 0" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab style={{ textTransform: "none" }} label="Connections" />
          <Tab style={{ textTransform: "none" }} label="Received" />
          <Tab style={{ textTransform: "none" }} label="Sent" />
        </Tabs>
      </Paper>

      {
        //Immediately Invoked Function
        (() => {
          if (value === 1) {
            return <AcceptList />;
          } else if (value === 2) {
            return <SendList />;
          } else {
            return <ActiveList isTeam={false} />;
          }
        })()
      }

      <ToastContainer autoClose={2000} />
    </Paper>
  );
};

export default MyConnections;
