import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import ProfilePic from '../images/avatar.jpg';
import BannerShop1 from '../images/banner-shop1.png';
import LogoShop1 from '../images/logo-shop1.png';
import BannerShop2 from '../images/banner-shop2.png';
import LogoShop2 from '../images/logo-shop2.png';
import axios from 'axios';
import { API_URL, BASEIMAGE, Base_Image_URL } from '../Constants';
import ConnectList from './People/ConnectList';
import RightSideBarConnectList from './People/RightSideBarConnectList';
import FeaturedProducts from './home/FeaturedProducts';
import Footer from './Footer';
import TopPick from './home/TopPick';

const styles = {
  flexContainer1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 45,
    background: '#FBFBFB',
    marginBottom: '20px',
  },
  flexContainer2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    margin: `0 12px 12px 12px`,
  },

  halfCircle1: {
    width: '50px',
    height: '35px',
    background: '#D1152D',
    borderRadius: `90px 90px 0 0`,
    marginLeft: '10px',
  },
  halfCircle2: {
    width: '50px',
    height: '25px',
    background: '#D1152D',
    borderRadius: `90px 90px 0 0`,
    marginRight: '15px',
  },
  title2: {
    marginBottom: '10px',
  },
  marginBottom: {
    marginBottom: '20px',
  },
  large: {
    width: 50,
    height: 50,
  },
  showAllBtn: {
    borderRadius: 25,
    textTransform: 'none',
    padding: '6px 30px',
    marginTop: '10px',
    marginBottom: '15px',
  },
  hoverBtn: {
    borderRadius: 25,
    textTransform: 'none',
    '&:hover': {
      background: '#D1152C',
      color: '#ffffff',
    },
  },

  shopLogo: {
    position: 'relative',
    top: '-50px',
    left: '100px',
  },
};

function RightSideBar(props) {
  const { classes } = props;
  const history = useHistory();

  // window.onscroll = function() {
  //   if(window.pageYOffset === 0) {
  //     alert('I AM AT THE TOP');
  //   }
  // };

  return (
    <div style={{ marginTop: '0px' }}>
      <FeaturedProducts />
      <RightSideBarConnectList />

      {/*  
//............................add shop........................................................
       <Paper>
       <div className={classes.flexContainer1}>
         <div className={classes.halfCircle1}>  </div>
         <div className={classes.title2}><Typography variant="subtitle2" color="primary">Add Shop</Typography></div>
         <div className={classes.halfCircle2}>  </div>
         </div>
         
       </Paper>

       <Paper className={classes.marginBottom}>
         <div >
           <img style={{ width: '100%'}} src={BannerShop1}/>
           <img className={classes.shopLogo} src={LogoShop1}/>
         </div>
         
         <Grid container justify="center">
          <Typography variant="subtitle2" color="primary">Best Store</Typography>
          <Typography style={{padding:'0 15px'}} variant="body2" >It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum</Typography>
          <Button style={{margin:'10px 0'}}
         className={classes.hoverBtn} size="small" variant="outlined"
        >
          Add This Shop
          </Button>
          </Grid>
       </Paper>
       <Paper>
         <div >
           <img style={{ width: '100%'}} src={BannerShop2}/>
           <img className={classes.shopLogo} src={LogoShop2}/>
         </div>
         
         <Grid container justify="center">
          <Typography variant="subtitle2" >Drink Your Best</Typography>
          <Typography style={{padding:'0 15px'}} variant="body2" >It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum</Typography>
          <Button style={{margin:'10px 0'}}
          className={classes.hoverBtn} size="small" variant="outlined"
        >
          Add This Shop
          </Button>
          </Grid>
       </Paper> */}

      {/* <Footer></Footer> */}
    </div>
  );
}

RightSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RightSideBar);
