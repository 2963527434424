import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

// Show hide password
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
      },
    },
  },
  marginAll: {
    marginBottom: theme.spacing(3),
  },
  textFieldInput: {
    width: "300px",
  },
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "30%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const ResetPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    values: { newPassword, confirmPassword },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    loading,
  } = props;

  const change = (name, e) => {
    console.log(e);
    console.log(name);
    e.persist();
    handleChange(e);

    setFieldTouched(name, true, false);

    // Show hide part
    setValues({ ...values, [name]: e.target.value });
  };

  // Show hide password
  const [values, setValues] = React.useState({
    newPassword: "",
    shownewPassword: false,
    confirmPassword: "",
    showconfirmPassword: false,
  });

  const handleClickShownewPassword = () => {
    setValues({ ...values, shownewPassword: !values.shownewPassword });
  };

  const handleMouseDownnewPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowconfirmPassword = () => {
    setValues({ ...values, showconfirmPassword: !values.showconfirmPassword });
  };

  const handleMouseDownconfirmPassword = (event) => {
    event.preventDefault();
  };
  // Show hide password end

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Typography style={{ marginRight: "160px", marginBottom: "6px" }}>
        Reset Password
      </Typography>

      {/* <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant="outlined"
        id="newPassword"
        name="newPassword"
        helperText={touched.newPassword ? errors.newPassword : ""}
        error={touched.newPassword && Boolean(errors.newPassword)}
        label="New Password"
        fullWidth
        type="newPassword"
        value={newPassword}
        onChange={change.bind(null, "newPassword")}
        size="small"
        className={classes.marginAll}
      /> */}

      <FormControl
        size="small"
        className={clsx(
          classes.margin,
          classes.textField,
          classes.marginAll,
          classes.borderRad,
          classes.textFieldInput
        )}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-newPassword">
          New Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-newPassword"
          type={values.shownewPassword ? "text" : "Password"}
          value={values.newPassword}
          onChange={change.bind(null, "newPassword")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShownewPassword}
                onMouseDown={handleMouseDownnewPassword}
                edge="end"
              >
                {values.shownewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={110}
          name="newPassword"
          helperText={touched.newPassword ? errors.newPassword : ""}
          error={touched.newPassword && Boolean(errors.newPassword)}
        />
      </FormControl>

      <FormControl
        size="small"
        className={clsx(
          classes.margin,
          classes.textField,
          classes.marginAll,
          classes.borderRad,
          classes.textFieldInput
        )}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-confirmPassword">
          Confirm Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-confirmPassword"
          type={values.showconfirmPassword ? "text" : "Password"}
          value={values.confirmPassword}
          onChange={change.bind(null, "confirmPassword")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowconfirmPassword}
                onMouseDown={handleMouseDownconfirmPassword}
                edge="end"
              >
                {values.showconfirmPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={135}
          name="confirmPassword"
          helperText={touched.confirmPassword ? errors.confirmPassword : ""}
          error={touched.confirmPassword && Boolean(errors.confirmPassword)}
        />
      </FormControl>

      {/* <CustomTextField
              InputProps={{
                classes: {
                  root: classes.textFieldInput
                },
              }}
            variant="outlined"
            id="confirmPassword"
            name="confirmPassword"
            helperText={touched.confirmPassword ? errors.confirmPassword : ""}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={change.bind(null, "confirmPassword")}
            size="small"
            className={classes.marginAll}
          /> */}

      <div className="flex flex-wrap items-center mb-4">
        <div className={classes.wrapper}>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            className={classes.marginAll}
            disabled={!isValid || loading}
            style={{
              borderRadius: 25,
              textTransform: "none",
              padding: "9px 50px",
            }}
          >
            Reset Password
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>

      <Link
        style={{ cursor: "pointer", marginBottom: "15px" }}
        onClick={() => history.push("/login")}
        variant="body2"
      >
        Try to Log In
      </Link>
    </form>
  );
};

export default ResetPassword;
