import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { API_URL } from '../../Constants';
import { Typography } from '@material-ui/core';
import Moment from 'react-moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from './../utilities/ConfirmDialog';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Eclair', 262, 16.0),
];

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default function TeamMember() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  let [teamMemberList, setTeamMemberList] = React.useState([]);
  let user = JSON.parse(localStorage.getItem('user'));
  const [hasMore, setHasMore] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + 'UserInfoes/MemberList?userId=' + user.Id + '&pageSize=10')
      .then((response) => {
        console.log('m l: ' + JSON.stringify(response.data));
        if (response.data.length < 10) setHasMore(false);
        setTeamMemberList(response.data);
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    getTeamMember(JSON.parse(localStorage.getItem('user')).Id);
  }, []);

  const getTeamMember = (id) => {
    setLoading(true);
    axios
      .get(API_URL + 'UserInfoes/MemberList?userId=' + id + '&pageSize=10')
      .then((response) => {
        console.log('m l: ' + JSON.stringify(response.data));
        if (response.data.length < 10) setHasMore(false);
        setTeamMemberList(response.data);
        setLoading(false);
      });
  };

  //infinitity Scroll start
  let [page, setPage] = React.useState(0);
  const fetchMoreData = () => {
    page++;
    setPage(page);
    axios
      .get(
        API_URL +
          'UserInfoes/MemberList?userId=' +
          user.Id +
          '&pageIndex=' +
          page +
          '&pageSize=10'
      )
      .then((response) => {
        if (response.data.length < 1) setHasMore(false);
        setTeamMemberList(teamMemberList.concat(response.data));
      });
  };
  //infinity Scroll end

  // Delete Team Member
  const onDelete = (guid) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    axios
      .delete(
        API_URL +
          'UserInfoes?deletedBy=' +
          JSON.parse(localStorage.getItem('user')).Id +
          '&guid=' +
          guid
      )
      .then(
        (res) => {
          toast.success('Team Member Deleted Successfully');
          getTeamMember(JSON.parse(localStorage.getItem('user')).Id);
        },
        (error) => {
          toast.error('Team Member Deletion Failed !!!');
        }
      );
  };

  return !loading && teamMemberList.length === 0 ? (
    <h5>No List Available</h5>
  ) : (
    <InfiniteScroll
      dataLength={teamMemberList.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {teamMemberList.length === 0 && !loading ? (
        <Typography align='center' color='primary' variant='h6'>
          No Team Member Found
        </Typography>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: '10px' }}>
          <Table className={classes.table} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell align=''>Name</StyledTableCell>
                <StyledTableCell align=''>Phone</StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
                <StyledTableCell align=''>
                  <Button
                    onClick={() => {
                      history.push('/add-team-member');
                    }}
                    color='secondary'
                    style={{
                      borderRadius: 25,
                      textTransform: 'none',
                      width: 140,
                      marginBottom: '5px',
                      color: '#D1152C',
                    }}
                    variant='contained'
                  >
                    Add Member
                  </Button>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMemberList.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component='th' scope='row'>
                    {row.Name}
                  </StyledTableCell>
                  <StyledTableCell align=''>{row.Phone}</StyledTableCell>
                  <StyledTableCell align='right'>
                    <Button
                      type='submit'
                      onClick={() => {
                        history.push('/edit-team-member/' + row.GUID);
                      }}
                      color='secondary'
                      style={{
                        borderRadius: 25,
                        textTransform: 'none',
                        width: 50,
                        marginBottom: '5px',
                        color: '#D1152C',
                      }}
                      variant='contained'
                    >
                      Edit
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    <Button
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: 'Are you sure to delete this Team Member?',
                          subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            onDelete(row.GUID);
                          },
                        });
                      }}
                      type='submit'
                      color='primary'
                      style={{
                        borderRadius: 25,
                        textTransform: 'none',
                        width: 100,
                      }}
                      variant='contained'
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ToastContainer autoClose={2000} />
    </InfiniteScroll>
  );
}
