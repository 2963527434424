import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 25,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  textFieldInput: {
    width: '300px',
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '10px 0',
  },
}));

const AddTeamMember = (props) => {
  const classes = useStyles();
  const {
    values: { Name, Phone, Password },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant='outlined'
        id='Name'
        name='Name'
        helperText={touched.Name ? errors.Name : ''}
        error={touched.Name && Boolean(errors.Name)}
        label='Name'
        value={Name}
        onChange={change.bind(null, 'Name')}
        size='small'
        className={classes.marginBottom}
      />

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant='outlined'
        id='Phone'
        name='Phone'
        helperText={touched.Phone ? errors.Phone : ''}
        error={touched.Phone && Boolean(errors.Phone)}
        label='Phone Number'
        value={Phone || ''}
        onChange={change.bind(null, 'Phone')}
        size='small'
        className={classes.marginBottom}
      />

      <CustomTextField
        InputProps={{
          classes: {
            root: classes.textFieldInput,
          },
        }}
        variant='outlined'
        id='Password'
        name='Password'
        autoComplete='new-password'
        helperText={touched.Password ? errors.Password : ''}
        error={touched.Password && Boolean(errors.Password)}
        label='Password'
        type='Password'
        value={Password}
        onChange={change.bind(null, 'Password')}
        size='small'
        className={classes.marginBottom}
      />

      <Button
        type='submit'
        size='small'
        variant='contained'
        color='primary'
        className={classes.marginBottom}
        disabled={!isValid}
        style={{ borderRadius: 25, textTransform: 'none', padding: '9px 25px' }}
      >
        Add New Team Member
      </Button>
      <ToastContainer autoClose={2000} />
    </form>
  );
};

export default AddTeamMember;
