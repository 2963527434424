import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import axios from "axios";
import { API_URL } from "../../Constants";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import EditTeam from "./EditTeam";
import { useParams } from "react-router-dom";

const useStyles = (theme) => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    maxWidth: 700,
    maxHeight: 1300,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  flexZone: {
    display: "flex",
    justifyContent: "flex-end",
  },
  topLabel: {
    width: "250px",
    height: "40px",
    background: "#D1152D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightTopHalfCircle: {
    height: "40px",
    width: "20px",
    borderRadius: "90px 0px 0px 90px",
    background: "#D1152D",
  },
  bottomRightTriangle: {
    width: "30px",
    height: "30px",
    background: "#D1152D",
    borderRadius: `90px 0 0px 0`,
  },
});
const EditTeamFormic = (props) => {
  let [isMessage, setIsMessage] = React.useState(false);
  let [myMessage, setMyMessage] = React.useState();
  let [myMessageColor, setMyMessageColor] = React.useState();

  let { id } = useParams();
  let [team, setTeam] = React.useState({});

  React.useEffect(() => {
    axios.get(API_URL + "UserTeams/" + id).then((response) => {
      console.log("team :" + JSON.stringify(response));
      setTeam(response.data);
    });
  }, []);

  const submit = (data, { resetForm }) => {
    console.log(data);
    let userInfo = JSON.parse(localStorage.getItem("user"));

    team.Name = data.Name;
    team.Phone = data.Phone;
    team.Email = data.Email;
    team.Description = data.Description;
    team.UpdatedBy = userInfo.Id;

    console.log(team);

    axios.put(API_URL + "UserTeams/" + id, team).then(
      (response) => {
        messaginForma("Team Updated Successfully", "green");
        resetForm({});
      },
      (error) => {
        messaginForma("Something went wrong", "#d1152c");
      }
    );
    messaginForma("Team Updated Successfully", "green");
  };

  let messaginForma = (myMes, myColor) => {
    setMyMessageColor(myColor);
    setIsMessage(true);
    setMyMessage(myMes);

    setTimeout(() => {
      setIsMessage(false);
      setMyMessage();
    }, 4000);
  };

  const validationSchema = Yup.object({
    Name: Yup.string("Enter a name").required("Name is required"),
  });

  const values = {
    Name: team.Name,
    Phone: team.Phone,
    Email: team.Email,
    Description: team.Description,
  };

  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexContainer}>
        <div className={classes.topLabel}>
          <Typography variant="body1" color="secondary">
            Edit Team
          </Typography>{" "}
        </div>
        <div className={classes.rightTopHalfCircle}> </div>
      </div>

      {isMessage && (
        <p
          style={{
            color: myMessageColor,
            textAlign: "center",
          }}
        >
          {myMessage}
        </p>
      )}

      <Formik
        enableReinitialize
        render={(props) => <EditTeam {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      />
      <div className={classes.flexZone}>
        <div className={classes.bottomRightTriangle}> </div>
      </div>
      <ToastContainer autoClose={2000} />
    </Paper>
  );
};

export default withStyles(useStyles)(EditTeamFormic);
