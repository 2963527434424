import React from 'react';
import { Route } from 'react-router-dom';
import { Grid, Typography, Container } from '@material-ui/core';
import LeftSideBar from '../components/LeftSideBar';
import NavBar from '../components/Navbar';
import LatestAd from '../components/home/LatestAd';
import TopPick from '../components/home/TopPick';
import RightSideBar from '../components/RightSideBar';
import TopPickFixed from '../components/home/TopPickFixed';

const HomepageLayout = ({ children, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  React.useEffect(() => {
    if (localStorage.getItem('user')) setIsAuthenticated(true);
  }, []);

  const handleAuthenticate = () => {
    setIsAuthenticated(false);
  };

  return (
    <>
      <Grid container>
        <Grid item>
          {' '}
          <NavBar
            authenticate={handleAuthenticate}
            isAuthenticated={isAuthenticated}
          />
        </Grid>
      </Grid>

      <Container
        maxWidth='xl'
        style={{ paddingTop: '75px', backgroundColor: '#F2F2F2' }}
      >
        <Grid container spacing={2}>
          <Grid item md={9} lg={9}>
            <LatestAd />
          </Grid>
          <Grid item md={3} lg={3}>
            <TopPick />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth='xl' style={{ backgroundColor: '#F2F2F2' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            style={{ paddingRight: '0px' }}
          >
            <LeftSideBar isAuthenticated={isAuthenticated} />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6}>
            {children}
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3}>
            <RightSideBar />
            <TopPickFixed />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const HomepageLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <HomepageLayout>
          <Component {...matchProps} />
        </HomepageLayout>
      )}
    />
  );
};

export default HomepageLayoutRoute;
