import { Avatar, Button, Paper, Typography } from '@material-ui/core';
import React from 'react';
import AddReply from './AddReply';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_URL } from './../../Constants';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '15px',
    width: '100%',
  },
  bgReply: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 25,
    padding: '10px',
    maxWidth: '400px',
  },
}));
const SingleReply = (props) => {
  const classes = useStyles();
  const { reply, key } = props;
  console.log('rep: ' + JSON.stringify(reply));
  return (
    <Paper key={key} elevation={0} className={classes.root}>
      <div>
        <a href={reply.userProfileLink} target='_blank'>
          <Avatar
            style={{ border: '1px solid rgba(0, 0, 0, 0.23)' }}
            alt={reply.RepliedByName}
            src={BASE_URL + reply.RepliedByProfilePic}
          />
        </a>
      </div>

      <div style={{ paddingLeft: '15px' }}>
        <div className={classes.bgReply}>
          <Typography variant='subtitle1' color='textPrimary'>
            {reply.RepliedByName}
          </Typography>

          <Typography
            style={{ wordBreak: 'break-all' }}
            color='textPrimary'
            variant='body2'
          >
            {reply.Reply}{' '}
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

export default SingleReply;
