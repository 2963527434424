

import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import axios from 'axios';
import { API_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import './content.css';



const useStyles = theme => ({
  paper: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
   // alignItems: 'center',
    maxWidth:700,
    maxHeight:8000,
   
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom:'20px'
  },
  flexZone: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  topLabel:{
     width:'400px',
     height:'40px',
     background: '#D1152D',
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
  },
  rightTopHalfCircle:{
    height:'40px',
    width:'20px',
    borderRadius: '90px 0px 0px 90px',
    background: '#D1152D',
   
},
bottomRightTriangle:{
     width:'30px',
     height:'30px',
     background: '#D1152D',
     borderRadius: `90px 0 0px 0`
},

});
  
const Privacy =(props)=> {
 
    const { classes } = props;
    return (
      <Paper className={classes.paper}>

      <div className={classes.flexContainer}>
      <div className={classes.topLabel}>
      <Typography variant="body1"  color="secondary">
      Privacy
     </Typography>  </div>
     <div className={classes.rightTopHalfCircle}>  </div>
      </div>
   
    <div style={{margin: "10px"}} className="infoBody">
    <p>Advertisements on <a href="http://prodeap.com">sundarbanX</a> must comply with our policies. Our policies provide rules on the types of products and services that can be offered for advertisement on <a href="http://prodeap.com">sundarbanX</a>. Advertisers are also responsible for complying with all applicable laws and regulations. Failure to comply may result in a variety of consequences, including the removal of advertisements and other content. If you repeatedly post content that violates <a href="http://prodeap.com">sundarbanX</a>'s policies, we may take additional action on your account. We reserve the right to reject, approve, or remove any advertisement for any reason, in our sole discretion.</p>
<p>&nbsp;</p>
<p><strong>Advertising Policy:</strong></p>
<p><strong>&nbsp;</strong></p>
<ol>
<li><strong> Adult Products:</strong></li>
</ol>
<p>Advertisements may not promote the advertisement, or use of adult products.</p>
<p><strong>Examples: </strong></p>
<ol>
<li>Products promoting family planning and contraception, which focus on the contraceptive features of the product, and not on sexual pleasure or sexual enhancement&nbsp; (OK)</li>
<li>Sex toys (Not OK)</li>
<li>Sexual enhancement products (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li><strong> Alcohol</strong></li>
</ol>
<p>Advertisements may not promote the advertisement of alcohol.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Books or DVDs about alcohol (OK)</li>
<li>Alcohol-related items for life safety - sanitizer making purpose. (OK)</li>
<li>Sale of alcoholic beverages (Not OK)</li>
<li>Kits for making alcohol (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="3">
<li><strong> Animals</strong></li>
</ol>
<p>Advertisements may not promote the advertisement of animals.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Animal cages (OK)</li>
<li>Products for animals (toys, collars, etc.) (OK)</li>
<li>Live animals - As per govt. law (OK)</li>
<li>Livestock - As per govt. law (OK)</li>
<li>Pets - As per govt. law (OK)</li>
<li>Any product or part, including but not limited to leather, skin, hide, fur, wool, or hair from any dogs, cats, or endangered or threatened animals (Not OK)</li>
<li>Prohibited animal parts, including but not limited to bone, teeth, horn, ivory, taxidermy, organs, external limbs, secretions, or carcasses (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="4">
<li><strong> Body Parts and Fluids</strong></li>
</ol>
<p>Advertisements may not promote the advertisement of human body parts or fluids.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Hair extensions and wigs (OK)</li>
<li>Blood (Not OK)</li>
<li>Urine (Not OK)</li>
<li>Body parts (Not OK)</li>
<li>Organs (Not OK)</li>
<li>Human tissue (Not OK)</li>
<li>Teeth (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="5">
<li><strong> Digital Media and Electronic Devices</strong></li>
</ol>
<p>Advertisements may not promote the advertisement of devices that facilitate or encourage streaming digital content in an unauthorized manner or interfering with the functionality of electronic devices.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Add-on equipment for streaming devices such as keyboards and remotes (OK)</li>
<li>Sale of streaming devices loaded with software that facilitates unauthorized access to content (Not OK)</li>
<li>Jailbroken or loaded devices (Not OK)</li>
<li>Jamming or descrambling devices (Not OK)</li>
<li>Wiretapping devices (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="6">
<li><strong> Documents, currency, and financial instruments</strong></li>
</ol>
<p>The advertisement may not promote real or fake documents, currency, financial instruments, and virtual currency</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Real and replica documents such as passports, IDs, or certificates (Not OK)</li>
<li>Real money (cash or cash equivalent instruments and coins) (Not OK)</li>
<li>Replica or prop money (Not OK)</li>
<li>Digital or cryptocurrency (Not OK)</li>
<li>Active bank credit or debit cards (Not OK)</li>
<li>Store credit cards or coupons (Not OK)</li>
<li>Pre-paid credit or debit cards (Not OK)</li>
<li>Cheques or checkbooks (Not OK)</li>
<li>Equipment to create counterfeit currency or financial instruments (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="7">
<li><strong> Gambling</strong></li>
</ol>
<p>The advertisement may not promote or facilitate online gambling for money or money's worth, including digital currencies. Online gambling includes gaming, betting, lotteries, raffles, casino, fantasy sports, bingo, poker, and sweepstakes in an online environment.</p>
<p>&nbsp;</p>
<ol start="8">
<li><strong> Hazardous goods and materials</strong></li>
</ol>
<p>The advertisement may not promote hazardous materials and substances.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Unauthorized chemical pesticides and insecticides (Not OK)</li>
<li>Asbestos, cyanide, chloroform, and carbon tetrachloride (Not OK)</li>
<li>Corrosive substances (Not OK)</li>
<li>Flammable and combustible substances and products (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="9">
<li><strong> Human exploitation and sexual services</strong></li>
</ol>
<p>The advertisement may not promote any form of human trafficking, prostitution, escort, or sexual services.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Persons for sale (Not OK)</li>
<li>Escort agencies (Not OK)</li>
<li>Commercial sex (Not OK)</li>
<li>Live shows for adult entertainment (Not OK)</li>
<li>Sexually suggestive services (For example: "texts to send her to get her in bed") (Not OK)</li>
<li>Child pornography (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="10">
<li><strong> Ingestible supplements</strong></li>
</ol>
<p>The advertisement may not promote ingestible supplements.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Anabolic steroids (Not OK)</li>
<li>Chitosan (Not OK)</li>
<li>Comfrey (Not OK)</li>
<li>Dehydroepiandrosterone (Not OK)</li>
<li>Ephedra (Not OK)</li>
<li>Human growth hormones (Not OK)</li>
<li>Protein bars and protein powder (Not OK)</li>
<li>Vitamins (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="11">
<li><strong> Medical and Healthcare Products</strong></li>
</ol>
<p>The advertisement may not promote medical and healthcare products and services, including medical devices, or smoking cessation products containing nicotine.</p>
<p><strong>Medical Devices:</strong></p>
<p>The advertisement may not promote the sale of medical devices.</p>
<ol>
<li>Lifestyle and fitness accessories, including watches (OK)</li>
<li>Contact lenses (Not OK)</li>
<li>Bandages and braces for physical injuries (Not OK)</li>
<li>Thermometers (Not OK)</li>
<li>Testing kits for medical conditions or diseases (Not OK)</li>
<li>First-aid kits (Not OK)</li>
<li>Breast Pumps (Not OK)</li>
</ol>
<ol start="12">
<li><strong> Medical Products and Services:</strong></li>
</ol>
<p>The advertisement may not promote the sale of medical products or services.</p>
<ol>
<li>Medical and cosmetic products and services including dermal fillers and Botox (Not OK)</li>
<li>Injectable products and intravenous services (Not OK)</li>
<li>Products, including food or drinks, that make health or medical claims (Not OK)</li>
</ol>
<ol start="13">
<li><strong> Smoking Cessation Products:</strong></li>
</ol>
<p>The advertisement may not promote the sale of smoking cessation products containing nicotine.</p>
<ol>
<li>Nicotine patches (Not OK)</li>
<li>Nicotine gum (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="14">
<li><strong> Misleading, Violent, or Hateful:</strong></li>
</ol>
<p>The advertisement may not contain misleading, violent, or hateful products and offers.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>The advertisement may not promote hate material, which includes, among other things, literature, memorabilia, symbols, and slogans associated with extremist or racist ideologies, or holocaust denial. (Not OK)</li>
<li>The advertisement that is misleading, shocking, sensational, or portrays excessive violence. (Not OK)</li>
<li>The advertisements may not solicit ratings or reviews. (Not OK)</li>
<li>The advertisement must not imply or attempt to generate negative self-perception in order to promote diet, weight loss, or other health-related products. (Not OK)</li>
<li>Person showing how loose his or her clothes fit. (Not OK)</li>
<li>Person with clothes that are too tight. (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="15">
<li><strong> No Item for Sale:</strong></li>
</ol>
<p>The advertisement may not promote humor or other content that does not offer any product for advertising.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>News (OK)</li>
<li>News and awareness posts (OK)</li>
<li>Listings in search of, in need of, or looking to buy (OK)</li>
<li>Donations (Not OK)</li>
<li>Jokes and memes (Not OK)</li>
<li>Lost and found posts (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="16">
<li><strong> Prescription Products, Drugs, and Drug Paraphernalia:</strong></li>
</ol>
<p>The advertisement may not promote the advertising of drugs, drug paraphernalia, or prescription products.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Prescription products, including prescription drugs (OK)</li>
<li>Drugs, including marijuana and marijuana products (Not OK)</li>
<li>Drug paraphernalia, including pipes and bongs (Not OK)</li>
</ol>
<ol start="17">
<li><strong> Products with Overtly Sexualized Positioning:</strong></li>
</ol>
<p>The advertisement may not advertise products or services in a sexually suggestive manner.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Zoomed in sexual images (Not OK)</li>
<li>Implied nudity&nbsp; (Not OK)</li>
<li>Implied sexual acts (Not OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="18">
<li><strong> Recalled Products:</strong></li>
</ol>
<p>The advertisement may not promote the advertising of recalled products.</p>
<p><strong>Examples:</strong></p>
<p>If you're unsure whether the item you're advertising has been recalled, look on the website of the item's manufacturer or relevant government entity.</p>
<p>&nbsp;</p>
<ol start="19">
<li><strong> Stolen Goods:</strong></li>
</ol>
<p>The advertisement may not promote the advertising of items that have been stolen.</p>
<p>&nbsp;</p>
<ol start="20">
<li><strong> Subscriptions and Digital Products:</strong></li>
</ol>
<p>The advertisement may not promote the advertisement of downloadable digital content, digital subscriptions, and digital accounts.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Authentic audio or video CDs, DVDs, and Blu Ray (OK)</li>
<li>Digital devices, including Smartphones, video game consoles, and TVs (OK)</li>
</ol>
<p>&nbsp;</p>
<ol start="21">
<li><strong> Tobacco Products and Related Paraphernalia:</strong></li>
</ol>
<p>The advertisement may not promote the advertising of tobacco products or tobacco paraphernalia.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Apparel featuring a tobacco brand logo. (OK)</li>
<li>Cigarettes, cigars, and chewing tobacco. (Not OK)</li>
<li>Tobacco pipes and paraphernalia. (Not OK)</li>
<li>Tobacco rolling machines. (Not OK)</li>
<li>Hookahs and hookah lounges. (Not OK)</li>
<li>Bongs. (Not OK)</li>
<li>Rolling papers. (Not OK)</li>
<li>Electronic cigarettes (E-cigarettes) or tobacco devices. (Not OK)</li>
</ol>
<p><strong>&nbsp;</strong></p>
<ol start="22">
<li><strong> Weapons, Ammunition, and Explosives:</strong></li>
</ol>
<p>The advertisement may not promote advertising, or the use of weapons, ammunition, and explosives.</p>
<p><strong>Examples:</strong></p>
<ol>
<li>Promoting safety training or licenses for legal weapons (OK)</li>
<li>Firearms and firearm parts (Not OK)</li>
<li>Paintball guns (Not OK)</li>
<li>BB Guns (Not OK)</li>
<li>Fireworks (Not OK)</li>
<li>Pepper spray (Not OK)</li>
<li>Tasers (Not OK)</li>
<li>Gun ranges (Not OK)</li>
<li>Gun shows (Not OK)</li>
</ol>
<p>&nbsp;</p>
<p><strong>Reward Policy:</strong></p>
<p>Welcome to <a href="https://prodeap.com">sundarbanX</a>!</p>
<p><a href="https://prodeap.com">sundarbanX</a> will not offer any direct monetary reward as the People's Republic of Bangladesh government is not allowing this and also <a href="https://prodeap.com">sundarbanX</a> is not permitted to do such kind of services.</p>
<p>&nbsp;</p>
<p>But, users, people, or browsers can refer anyone to <a href="https://prodeap.com">sundarbanX</a> and in return, the referrer will get reward points.</p>
<ol>
<li>The Referrer will get 100 reward points for every successful or active invited number.</li>
<li>100 reward points equal to 10BDT.</li>
<li>Referrers can claim to withdraw 1000 reward points (minimum) by exchanging to BDT.</li>
<li>Referrer can claim to convert the reward points to the advertisement post charge.</li>
<li>Anyone can refer to 1 number at a single time in his lifetime.</li>
<li>1 referral number will be valid for 72 hours and after that, the number will be invalid from the referrer list.</li>
<li>Every user, person, or browser can refer to 10 numbers per day.</li>
</ol>
    </div>
          
       <div className={classes.flexZone}>
      
      <div className={classes.bottomRightTriangle}>  </div>
       </div>
       <ToastContainer autoClose={2000} />
      </Paper>
    );
  }

export default withStyles(useStyles)(Privacy);
