import React from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IMG from "../../images/toppic.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Card, CardContent, CardMedia, CardActions } from "@material-ui/core";
import { API_URL, BASEIMAGE, Base_Image_URL } from "../../Constants";
import Footer from "./../Footer";
import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles((theme) => ({
  card: {
    maxHeight: 350,
  },
  rectangle: {
    height: "28px",
    background: "#D1152C",
    display: "flex",
    justifyContent: "center",
  },
  media: {
    height: 150,
    marginLeft: 15,
    marginRight: 15,
  },
  title: {
    fontSize: 15,
    paddingTop: 11,
    paddingBottom: 9,
  },
}));

const TopPickFixed = (props) => {
  const [loading, setLoading] = React.useState(false);
  let [topPicProduct, setTopPicProduct] = React.useState([]);

  // Sticky Part
  let [sidebarPosition, setSidebarPosition] = React.useState("static");
  let [top, setTop] = React.useState(300);
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setSidebarPosition("sticky");
        setTop(75);
      }
    });
  });
  // Sticky Part

  React.useEffect(() => {
    setLoading(true);
    axios.get(API_URL + "Products/TopPick?pageSize=" + 3).then((response) => {
      console.log("top pic: " + JSON.stringify(response));
      setTopPicProduct(response.data);
      setLoading(false);
    });
  }, []);

  const history = useHistory();
  const classes = useStyles();

  return (
    <div style={{ position: sidebarPosition, top: top }}>
      <div
        className={classes.rectangle}
        style={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}
      >
        <Typography variant="h6" color="secondary">
          Top Pick
        </Typography>
      </div>

      <Carousel>
        {!loading &&
          topPicProduct?.map((product, i) => (
            <Card className={classes.card}>
              <CardContent className={classes.title}>
                <Typography variant="body2" color="primary">
                  {product?.Title}
                </Typography>
              </CardContent>

              {product.PictureListView.length >= 1 ? (
                product.PictureListView[i]?.FilePath === "/Upload//" ? (
                  <a href={"/ads/" + product.GUID} target="_blank">
                    <CardMedia
                      className={classes.media}
                      image={Base_Image_URL + "Product/Small/"}
                      title={product.Title}
                    />
                  </a>
                ) : (
                  <a href={"/ads/" + product.GUID} target="_blank">
                    <CardMedia
                      className={classes.media}
                      image={
                        BASEIMAGE + product.PictureListView[0]?.FileLinkSmall
                      }
                      title={product.Title}
                    />
                  </a>
                )
              ) : (
                <CardMedia
                  className={classes.media}
                  image={IMG}
                  title="head phone"
                />
              )}

              <CardContent>
                {!product.IsNegotiable && !product.IsPriceRange && (
                  <Typography variant="subtitle2">
                    Asking Price: {product.RegularPrice} Tk
                  </Typography>
                )}
                {product.IsNegotiable && (
                  <Typography variant="subtitle2">
                    Asking Price (Negotiable): {product.RegularPrice} Tk
                  </Typography>
                )}
                {product.IsPriceRange && (
                  <Typography variant="subtitle2">
                    Price Range:{product.PriceFrom} - {product.PriceTo} Tk
                  </Typography>
                )}
              </CardContent>
            </Card>
          ))}
      </Carousel>

      <div style={{ paddingTop: "8px" }}>
        <Footer />
      </div>
    </div>
  );
};

export default TopPickFixed;
