import React from "react";
import {Link} from 'react-router-dom';
import {
  withStyles,
  
} from "@material-ui/core";
import UserFeaturedProducts from "./UserFeaturedProducts";
import UserTopPick from "./UserTopPick";
import Footer from "../Footer";

function UserRightSidebar({userId}) {
  
  return (
    <div style={{marginTop:'10px'}}>  
         <UserTopPick userId={userId}/>
        
         <UserFeaturedProducts userId={userId}/>
         <Footer></Footer>
        
    </div>
  );
}



export default UserRightSidebar;
