import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ListSubheader } from "@material-ui/core";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
// import SendIcon from '@material-ui/icons/Send';
import { grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
// import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
// import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
// import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import VolumeDownOutlinedIcon from "@material-ui/icons/VolumeDownOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
// import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import ShopOutlinedIcon from "@material-ui/icons/ShopOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
// import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
// import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import RecentActorsOutlinedIcon from "@material-ui/icons/RecentActorsOutlined";
import { useLocation } from "react-router-dom";
import AdjustIcon from "@material-ui/icons/Adjust";
import { PaymentOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    maxWidth: 360,
    backgroundColor: grey[100],
    borderLeft: "2px solid grey[100]",
    margin: 0,
    position: "sticky",
  },
}));

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(209, 21, 44, 0.05)",
      color: "#d1152c",
      borderLeft: "2px solid #d1152c",
    },
    // '&$selected:hover': {
    //   backgroundColor: 'purple',
    //   color: 'white',
    // },
    "&:hover": {
      backgroundColor: "rgba(209, 21, 44, 0.04)",
    },
  },
  selected: {},
})(MuiListItem);

const LeftSideBar = ({ isAuthenticated }) => {
  // m
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = React.useState(location.pathname);

  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);

  let [sidebarPosition, setSidebarPosition] = React.useState("static");
  let [top, setTop] = React.useState(300);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setSidebarPosition("sticky");
        setTop(75);
      }
    });
  });

  const handleMenuClick = (tarString) => {
    console.log(history.location.pathname);
    console.log("path: " + tarString);
    // if (selectedIndex !== tarString) {
    if (history.location.pathname !== tarString) {
      setSelectedIndex(tarString);
      history.push(tarString);
    }
  };

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  const handleMyAd = () => {
    setSelectedIndex("/my-ad");
    if (!localStorage.getItem("user")) {
      history.push("/login", { redirectPath: "/my-ad" });
    } else {
      history.push("/my-ad");
    }
  };
  return (
    <div style={{ position: sidebarPosition, top: top }}>
      {isAuthenticated && (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
            ></ListSubheader>
          }
          className={classes.root}
        >
          {/* <ListItem color="primary"  button onClick={()=>{history.push('/')}}>
         
         <DashboardIcon/>&nbsp; <ListItemText primary="Dashboard" />
        </ListItem> */}

          {/* <ListItem button>
         <HelpOutlineOutlinedIcon/>&nbsp; <ListItemText primary="About" />
        </ListItem>
        <ListItem button onClick={handleClick}>
          
        <NotificationsActiveOutlinedIcon/>&nbsp;  <ListItemText primary="Notifications" />
        </ListItem>
        <ListItem color="primary"  button onClick={()=>{history.push('/shop-list')}}>
         
        <ShoppingCartOutlinedIcon/>&nbsp; <ListItemText primary="Shops" />
       </ListItem>
       */}
          <ListItem
            button
            selected={selectedIndex === "/my-ad"}
            onClick={handleMyAd}
          >
            <VolumeDownOutlinedIcon />
            &nbsp; <ListItemText primary="My Advertisement" />
          </ListItem>

          <ListItem
            button
            selected={selectedIndex === "/reward-point"}
            onClick={() => handleMenuClick("/reward-point")}
          >
            <AccountBalanceWalletOutlinedIcon />
            &nbsp; <ListItemText primary="Rewards Point" />
          </ListItem>

          <ListItem
            button
            selected={selectedIndex === "/payment"}
            onClick={() => handleMenuClick("/payment")}
          >
            <PaymentOutlined></PaymentOutlined>
            &nbsp; <ListItemText primary="Payment" />
          </ListItem>

          {/* <ListItem button>
        
       <RateReviewOutlinedIcon/>&nbsp;  <ListItemText primary="Customer Reviews" />
      </ListItem> */}
          <ListItem
            button
            selected={selectedIndex === "/add-shop"}
            onClick={() => handleMenuClick("/add-shop")}
          >
            <AddBoxOutlinedIcon />
            &nbsp; <ListItemText primary="Create Shop" />
          </ListItem>
          <ListItem
            color="primary"
            button
            selected={selectedIndex === "/manage-shop"}
            onClick={() => handleMenuClick("/manage-shop")}
          >
            <ShopOutlinedIcon />
            &nbsp; <ListItemText primary="Manage Shop" />
          </ListItem>
          {/* <ListItem color="primary"  button onClick={()=>{history.push('/shop')}}>
         
       <StoreOutlinedIcon/>&nbsp;  <ListItemText primary="My Shop" />
       </ListItem> */}
          <ListItem
            color="primary"
            button
            selected={selectedIndex === "/my-profile"}
            onClick={() => handleMenuClick("/my-profile")}
          >
            <AccountBoxOutlinedIcon />
            &nbsp; <ListItemText primary="My Profile" />
          </ListItem>

          {/* <ListItem
            color="primary"
            button
            selected={selectedIndex === "/team-member"}
            onClick={() => handleMenuClick("/team-member")}
          >
            <GroupAddOutlinedIcon />
            &nbsp; <ListItemText primary="Team Member" />
          </ListItem> */}

          <ListItem
            button
            selected={selectedIndex === "/add-team"}
            onClick={() => handleMenuClick("/add-team")}
          >
            <AddBoxOutlinedIcon />
            &nbsp; <ListItemText primary="Create Team" />
          </ListItem>
          <ListItem
            color="primary"
            button
            selected={selectedIndex === "/manage-team"}
            onClick={() => handleMenuClick("/manage-team")}
          >
            <AdjustIcon />
            &nbsp; <ListItemText primary="Manage Team" />
          </ListItem>

          <ListItem
            color="primary"
            button
            selected={selectedIndex === "/team-work"}
            onClick={() => handleMenuClick("/team-work")}
          >
            <AdjustIcon />
            &nbsp; <ListItemText primary="Team Work" />
          </ListItem>

          <ListItem
            color="primary"
            button
            selected={selectedIndex === "/invited-people-list"}
            onClick={() => handleMenuClick("/invited-people-list")}
          >
            <RecentActorsOutlinedIcon />
            &nbsp; <ListItemText primary="Invited List" />
          </ListItem>
          <ListItem
            color="primary"
            button
            selected={selectedIndex === "/my-connections"}
            onClick={() => handleMenuClick("/my-connections")}
          >
            <SupervisedUserCircleOutlinedIcon />
            &nbsp; <ListItemText primary="My Network" />
          </ListItem>

          <ListItem
            color="primary"
            button
            selected={selectedIndex === "/connect-list"}
            onClick={() => handleMenuClick("/connect-list")}
          >
            <GroupAddOutlinedIcon />
            &nbsp; <ListItemText primary="Connect People" />
          </ListItem>
        </List>
      )}
    </div>
  );
};
export default LeftSideBar;
