import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import TokenInterceptorService from './components/auth/TokenInterceptor';
//import {BrowserRouter} from 'react-router-dom'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-104756009-1'); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);

TokenInterceptorService();
ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
