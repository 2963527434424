import { Avatar, Button, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from "react-router-dom";
import ProfilePic from '../../images/avatar.jpg';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios"
import { API_URL, IMAGE_URL } from '../../Constants';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import './People.css';

const useStyles = makeStyles(theme => ({
  flexContainer1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'flex-end',
    height:45,
    background:'#FBFBFB',
    marginBottom:'20px'
  },
  flexContainer2: {
    display: 'flex',
    justifyContent: 'space-between',
  // alignItems: 'space-between',
    alignContent: 'space-between',
   // alignItems:'stretch',
    height:50,
    margin:`0 12px 12px 12px`
  },
  
  halfCircle1:{
    width:'50px',
     height:'35px',
     background: '#D1152D',
     borderRadius: `90px 90px 0 0`,
     marginLeft:'10px'
  },
  halfCircle2:{
    width:'50px',
    height:'25px',
    background: '#D1152D',
    borderRadius: `90px 90px 0 0`,
    marginRight:'15px'
  },
  title2:{
     marginBottom:'10px'
  },
  marginBottom:{
 // marginBottom:'20px'
  },
  large: {
    width: 50,
    height: 50,
     marginLeft:'10px'
  },
  showAllBtn:{
    borderRadius:25,
    textTransform: "none",
    padding:'6px 30px',
    marginTop:'10px',
    marginBottom:'15px',
    
  },
  hoverBtn:{
    borderRadius: 25,
    textTransform: "none",
    '&:hover': {
      background: "#D1152C",
      color:'#ffffff'
   }
  },
  
 
}));
const ConnectList=props=>{
    const classes = useStyles();
    const history = useHistory();
    let [connectList,setConnectList]=React.useState([]);
    let [index,setIndex]=React.useState(-1);

    //JSON.parse(localStorage.getItem('user')).Id
    React.useEffect(()=>{
       axios.get(API_URL+'UserInfoes/ConnectList?userId='+JSON.parse(localStorage.getItem('user')).Id+'&pageSize=10').then(res=>{
           setConnectList(res.data)
       })
    },[])

    const [hasMore,setHasMore]=React.useState(true)
    let[page,setPage]=React.useState(0);
    const fetchMoreData=()=>{
      page++;
      setPage(page);
         axios.get(API_URL+'UserInfoes/ConnectList?userId='+JSON.parse(localStorage.getItem('user')).Id+'&pageIndex='+page+'&pageSize=10')
         .then(response => {
           if(response.data.length<10) setHasMore(false);
           setConnectList(connectList.concat(response.data))
          
        });
     }


    const connectPeople=(id,list,index)=>{
     // console.log(id)
      let loggedInUserId=JSON.parse(localStorage.getItem('user')).Id;
      axios.post(API_URL+'UserConnections',{ConnectedBy:loggedInUserId,ConnectedWith:id}).then(res=>{
        if(res.data.Meta.Status==1){
          toast.success(res.data.Meta.Message);
          delete list[index]
           setIndex(index)
          }else{
            toast.error("Something Went Wrong");    
          }
    })
    }

return (
  <Paper className={classes.marginBottom}>
  <InfiniteScroll
  dataLength={connectList.length}
  next={fetchMoreData}
  hasMore={hasMore}
  loader={<p>loading</p>}
  endMessage={
    <p style={{ textAlign: "center" }}>
      <b>Yay! You have seen it all</b>
    </p>
  }
>
  <div>
    <div className={classes.flexContainer1}>
      <div className={classes.halfCircle1}>  </div>
      <div className={classes.title2}><Typography variant="subtitle2" color="primary">Connect People</Typography></div>
      <div className={classes.halfCircle2}>  </div>
      </div>

     {
      connectList.map((item,i) => ( 
        <Grid  container style={{marginBottom:'5px'}}>
          <Grid item md={2}>
            <a href={"/" + item.SeoLink}>
              <Avatar variant="circle" alt={item.Name} src={IMAGE_URL+item.ProfilePicturePath} className={classes.large} />
            </a>
          </Grid>
          <Grid item md={8} className="conPeo">
            <a href={"/" + item.SeoLink}>
              <Typography variant="subtitle2">{item.Name}</Typography >
            </a>
          <Typography style={{fontSize: "13px"}} variant="body2">{item.CityName}, {item.StateName}</Typography >
          </Grid>
          <Grid item md={2}>
          <Button onClick={(e)=>connectPeople(item.Id,connectList,i,e)} className={classes.hoverBtn} size="small"  variant="outlined">Connect</Button>
          </Grid>         
        </Grid>   
       ))}

{
  history.location.pathname=='/connect-list'?null:
  <Grid container justify="center">
  <Button
  type="submit"
  size="small"
  variant="contained"
  color="primary"
  className={classes.showAllBtn}
  onClick={()=>history.push('/connect-list')}
>
  Show All
  </Button>
  </Grid>
}
   
       <ToastContainer autoClose={2000} />
       </div>
    </InfiniteScroll>
    </Paper>
)
}

export default  ConnectList;
