import React from "react";
import { useHistory, useParams } from "react-router-dom";

const RedirectMe = () => {
  const history = useHistory();
  const params = useParams();

  const callMe = () => {
    history.push("/");
  };
  callMe();
  return <div></div>;
};

export default RedirectMe;
