import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { API_URL } from "../../Constants";
import { Typography } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default function RewardsPoint() {
  const classes = useStyles();
  let [points, setPoints] = React.useState([]);
  let [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios
      .get(
        API_URL +
          "UserInfoes/RewardPointList?userId=" +
          JSON.parse(localStorage.getItem("user")).Id
      )
      .then((res) => {
        setPoints(res.data);
        setLoading(false);
      });
  }, []);

  return loading ? (
    <Typography variant="body2">
      <h4>Loading...</h4>
    </Typography>
  ) : (
    <>
      <TableContainer component={Paper} style={{ marginTop: "10px" }}>
        {points.length == 0 ? (
          // <Typography align="center" color="primary" variant="h6">No Rewards Point Available</Typography>
          <p style={{ textAlign: "center", color: "#d1152c" }}>
            No Rewards Point Available
          </p>
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Point Type</StyledTableCell>
                <StyledTableCell align="right">Reward Points</StyledTableCell>
                {/* <StyledTableCell align="right">ReferredByUserId</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {points.map((row) => (
                <StyledTableRow key={row.ReferredByUserId}>
                  <StyledTableCell component="th" scope="row">
                    {row.PointType}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.RewardPoints}
                  </StyledTableCell>
                  {/* <StyledTableCell align="right">{row.ReferredByUserId}</StyledTableCell> */}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}
