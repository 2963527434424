import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { API_URL } from "../../Constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Paper } from "@material-ui/core";
import TeamInvitePeople from "./TeamInvitePeople";
import { useParams } from "react-router";

const useStyles = (theme) => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    maxWidth: 700,
    maxHeight: 900,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  flexZone: {
    display: "flex",
    justifyContent: "flex-end",
  },
  topLabel: {
    width: "400px",
    height: "40px",
    background: "#D1152D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightTopHalfCircle: {
    height: "40px",
    width: "20px",
    borderRadius: "90px 0px 0px 90px",
    background: "#D1152D",
  },
  bottomRightTriangle: {
    width: "30px",
    height: "30px",
    background: "#D1152D",
    borderRadius: `90px 0 0px 0`,
  },
});

const TeamInvitePeopleFormic = (props) => {
  const values = { Name: "", Phone: "", Email: "", CreatedBy: 1 };
  const { classes } = props;
  const team = useParams();
  // const ownerPhone = props.location.ownerPhone;
  // const ownerId = props.location.ownerId;

  let [isMessage, setIsMessage] = React.useState(false);
  let [myMessage, setMyMessage] = React.useState();
  let [myMessageColor, setMyMessageColor] = React.useState();

  const submit = (data, { resetForm }) => {
    let userInfo = JSON.parse(localStorage.getItem("user"));
    let referedInfo = {
      Name: data.Name,
      Phone: data.Phone,
      Email: data.Email,
      CreatedBy: userInfo.Id,
      ReferredBy: team.ownerId, //userInfo.Id, //TeamOwnerId hobe
      ReferredByMobile: team.ownerPhone, //empty string
    };

    console.log(referedInfo);

    axios.post(API_URL + "UserInfoes/Refer", referedInfo).then((res) => {
      console.log(res);
      if (res.data.Meta.Status === 1) {
        messaginForma("Invite people Successfully", "green");
        resetForm({});
      } else {
        messaginForma(res.data.Meta.Message, "#d1152c");
      }
    });
  };

  const validationSchema = Yup.object({
    Name: Yup.string("Enter a name").required("Name is required"),
    Phone: Yup.string("Enter a Phone Number")
      .required("Phone Number is required")
      .matches(
        /^(01[3456789])(\d{8})$/,
        "Please Enter valid Bangladeshi Phone Number"
      ),
  });

  let messaginForma = (myMes, myColor) => {
    setMyMessageColor(myColor);
    setIsMessage(true);
    setMyMessage(myMes);

    setTimeout(() => {
      setIsMessage(false);
      setMyMessage();
    }, 4000);
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexContainer}>
        <div className={classes.topLabel}>
          <Typography variant="body1" color="secondary">
            {/* Invite Your Friend to prodeap.com */}
            Team: {team.Name}
          </Typography>{" "}
        </div>
        <div className={classes.rightTopHalfCircle}> </div>
      </div>

      {isMessage && (
        <p
          style={{
            color: myMessageColor,
            textAlign: "center",
          }}
        >
          {myMessage}
        </p>
      )}

      <Formik
        enableReinitialize
        render={(props) => <TeamInvitePeople {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      />
      <div className={classes.flexZone}>
        <div className={classes.bottomRightTriangle}> </div>
      </div>
      <ToastContainer autoClose={2000} />
    </Paper>
  );
};

export default withStyles(useStyles)(TeamInvitePeopleFormic);
