import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { API_URL } from "../../Constants";
import { Typography } from "@material-ui/core";
import DuePayment from "./DuePayment";
import ProcessingPayment from "./ProcessingPayment";
import PaidPayment from "./PaidPayment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default function RewardsPoint() {
  const classes = useStyles();

  return (
    <>
      <DuePayment></DuePayment>

      <ProcessingPayment></ProcessingPayment>

      <PaidPayment></PaidPayment>
    </>
  );
}
