import React from "react";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import axios from "axios";
import { API_URL } from "../../Constants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import AddTeam from "./AddTeam";

const useStyles = (theme) => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    maxWidth: 700,
    maxHeight: 900,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  flexZone: {
    display: "flex",
    justifyContent: "flex-end",
  },
  topLabel: {
    width: "250px",
    height: "40px",
    background: "#D1152D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightTopHalfCircle: {
    height: "40px",
    width: "20px",
    borderRadius: "90px 0px 0px 90px",
    background: "#D1152D",
  },
  bottomRightTriangle: {
    width: "30px",
    height: "30px",
    background: "#D1152D",
    borderRadius: `90px 0 0px 0`,
  },
});

const AddTeamFormic = (props) => {
  let [isMessage, setIsMessage] = React.useState(false);
  let [myMessage, setMyMessage] = React.useState();
  let [myMessageColor, setMyMessageColor] = React.useState();

  const submit = (data, { resetForm }) => {
    console.log("create team: " + JSON.stringify(data));
    let userInfo = JSON.parse(localStorage.getItem("user"));
    let team = {
      OwnerId: userInfo.Id,
      Name: data.Name,
      Email: data.Email,
      Phone: data.Phone,
      Description: data.Description,
      CreatedBy: userInfo.Id,
    };

    axios.post(API_URL + "UserTeams", team).then((response) => {
      if (response.data.Meta.Status === 1) {
        messaginForma("Team added Successfully", "green");
        resetForm({});
      } else {
        messaginForma("Something went wrong", "#d1152c");
      }
    });
  };

  let messaginForma = (myMes, myColor) => {
    setMyMessageColor(myColor);
    setIsMessage(true);
    setMyMessage(myMes);

    setTimeout(() => {
      setIsMessage(false);
      setMyMessage();
    }, 4000);
  };

  const validationSchema = Yup.object({
    Name: Yup.string("Enter a name").required("Name is required"),
  });

  const values = {
    Name: "",
    Email: "",
    Phone: "",
    Description: "",
  };

  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexContainer}>
        <div className={classes.topLabel}>
          <Typography variant="body1" color="secondary">
            Create New Team
          </Typography>{" "}
        </div>
        <div className={classes.rightTopHalfCircle}> </div>
      </div>

      {isMessage && (
        <p
          style={{
            color: myMessageColor,
            textAlign: "center",
          }}
        >
          {myMessage}
        </p>
      )}

      <Formik
        render={(props) => <AddTeam {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      />
      <div className={classes.flexZone}>
        <div className={classes.bottomRightTriangle}> </div>
      </div>
      <ToastContainer autoClose={2000} />
    </Paper>
  );
};

export default withStyles(useStyles)(AddTeamFormic);
