import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withStyles, Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL, BASEIMAGE } from "../../Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonCard from "../../layouts/SkeletonCard";
import ConfirmDialog from "../utilities/ConfirmDialog";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    maxHeight: theme.spacing(50),
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
  triangleBottomRight: {
    width: 0,
    height: 0,
    borderRight: "110px solid #D1152C",
    borderTop: "35px solid transparent",
    borderLeft: "300px solid transparent",
    borderBottom: "50px solid #D1152C",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  spec: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
  },
  large: {
    height: 140,
    width: 140,
  },
}));

const TeamWork = (props) => {
  const classes = useStyles();
  const history = useHistory();

  let [isMessage, setIsMessage] = React.useState(false);
  let [myMessage, setMyMessage] = React.useState();
  let [myMessageColor, setMyMessageColor] = React.useState();

  let [teams, setTeams] = React.useState([]);
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [loading, setLoading] = React.useState(false);
  const userId = JSON.parse(localStorage.getItem("user")).Id;

  const url = API_URL + "UserInfoes/TeamList?userId=" + userId;

  React.useEffect(() => {
    getTeamBySellerId(userId);
  }, []);

  const getTeamBySellerId = (id) => {
    setLoading(true);
    axios.get(url).then((res) => {
      setTeams(res.data);
      setLoading(false);
      console.log("T w: " + id, JSON.stringify(res.data));
    });
  };

  const onDelete = (teamId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    axios
      .delete(
        API_URL +
          "UserTeamToUserInfoMappings/ByUser?userId=" +
          userId +
          "&teamId=" +
          teamId
      )
      .then(
        (res) => {
          messaginForma("Leave Team Successfully", "green");
          getTeamBySellerId(userId);
        },
        (error) => {
          messaginForma("Team Leave operation Failed !!!", "#d1152c");
        }
      );
  };

  let messaginForma = (myMes, myColor) => {
    setMyMessageColor(myColor);
    setIsMessage(true);
    setMyMessage(myMes);

    setTimeout(() => {
      setIsMessage(false);
      setMyMessage();
    }, 4000);
  };

  const [hasMore, setHasMore] = React.useState(true);
  let [page, setPage] = React.useState(0);
  const fetchMoreData = () => {
    page++;
    setPage(page);
    axios.get(url + "&pageIndex=" + page).then((response) => {
      if (response.data.length < 10) setHasMore(false);
      setTeams(teams.concat(response.data));
    });
  };

  return loading ? (
    <Typography variant="body2">
      <h4>Loading...</h4>
    </Typography>
  ) : !loading && teams.length === 0 ? (
    <p style={{ textAlign: "center", color: "#d1152c" }}>
      You Have No Team To Work
    </p>
  ) : (
    <InfiniteScroll
      dataLength={teams.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<SkeletonCard />}
      scrollableTarget="scrollableDiv"
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {isMessage && (
        <p
          style={{
            color: myMessageColor,
            textAlign: "center",
            paddingTop: "10px",
          }}
        >
          {myMessage}
        </p>
      )}

      {teams?.map((team, index) => (
        <Paper className={classes.root} key={index}>
          <div style={{ alignSelf: "center" }}>
            <div className={classes.spec}>
              <Typography variant="subtitle2" color="primary">
                Name:
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                <Link
                  to={{
                    pathname: `/team-work-invite-people/${team.TeamName}/${team.TeamOwnerId}/${team.TeamOwnerPhone}`,
                    // ownerId: team.TeamOwnerId,
                    // ownerPhone: team.TeamOwnerPhone,
                  }}
                >
                  &nbsp;{team.TeamName}
                </Link>
              </Typography>
            </div>

            <Typography variant="subtitle2">Email: {team.TeamEmail}</Typography>
            <Typography variant="subtitle2">
              Contact: {team.TeamPhone}
            </Typography>
          </div>
          <div className={classes.flexContainer}>
            <Button
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to leave this Team?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    onDelete(team.TeamId);
                  },
                });
              }}
              type="submit"
              color="primary"
              style={{ borderRadius: 25, textTransform: "none", width: 160 }}
              variant="contained"
            >
              Leave the Team
            </Button>
          </div>
        </Paper>
      ))}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ToastContainer autoClose={2000} />
    </InfiniteScroll>
  );
};

export default withStyles(useStyles)(TeamWork);
