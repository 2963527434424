import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Dropzone from "react-dropzone";
import "./dropzone.css";
import {
  Typography,
  GridList,
  GridListTile,
  Container,
  Input,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Switch,
} from "@material-ui/core";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { API_URL, Base_Image_URL, IMAGE_URL } from "../../Constants";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TagInput from "../home/TagInput";
import ReactTags from "react-tag-autocomplete";

// New Tag
import InputTags from "react-input-tags-hooks";
import "react-input-tags-hooks/build/index.css"; // required
import MyTag from "../MyTag/MyTag";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  gridListDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  triangleBottomRight: {
    width: 0,
    height: 0,
    borderRight: "150px solid red",
    borderTop: "40px solid transparent",
    borderLeft: "400px solid transparent",
    borderBottom: "60px solid red",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
const PostAdForm = (props) => {
  const classes = useStyles();
  let {
    values: {
      Title,
      RootCategoryId,
      SubCategoryId,
      SubSubCategoryId,
      AskingPrice,
      MarketPrice,
      Description,
      Tags,
      IsNegotiable,
      IsPriceRange,
      PriceFrom,
      PriceTo,
      UsedStatus,
      UsedDuration,
      UsedDurationUnit,
      ShopId,
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
  } = props;

  let [rootCategories, setRootCategories] = React.useState([]);
  let [subCategories1, setSubCategories1] = React.useState([]);
  let [subCategories2, setSubCategories2] = React.useState([]);
  let [tileData, setTileData] = React.useState([]);
  let [hasSubCategory1, setHasSubCategory1] = React.useState(false);
  let [hasSubCategory2, setHasSubCategory2] = React.useState(false);
  let [allImageIds, setAllImageIds] = React.useState([]);
  let [uploadPercentage, setUploadPercentage] = React.useState(0);
  let [shopList, setShopList] = React.useState([{ Id: 0, Name: "No Shop" }]);

  let [subCatId, setSubCatId] = React.useState(1);
  let [suggestionsTag, setSuggestionsTag] = React.useState([]);
  let [isUsed, setIsUsed] = React.useState(false);
  let [udUnit, setUdUnit] = React.useState("months");

  React.useEffect(() => {
    // Shop list
    axios
      .get(
        API_URL +
          "Shops/BySeller?sellerId=" +
          JSON.parse(localStorage.getItem("user")).Id +
          "&pageSize=50"
      )
      .then((response) => {
        setShopList(shopList.concat(response.data));
        console.log("m s: " + JSON.stringify(response));
      });

    axios.get(API_URL + "ProductCategories/All").then((response) => {
      console.log(response);
      setRootCategories(response.data);
    });

    // Tags Value calculation
    // load suggestionsTag
    axios.get(API_URL + "Tags/All").then((response) => {
      setSuggestionsTag(response.data);
      // console.log("m a: " + [JSON.stringify(response.data)]);
    });
  }, []);

  let [tags, setTags] = React.useState([
    // { id: 1, name: "Apples" },
    // { id: 2, name: "Pears" },
  ]);

  const reactTags = React.createRef();

  const onDelete = (e) => {
    setTags(tags.filter((tag, index) => index !== e));
  };

  const onAddition = (e) => {
    setTags([].concat(tags, e));
  };

  let tagString;
  const makeTagString = () => {
    const tagArray = tags.map(function (item) {
      // return item["name"];
      return item;
    });

    tagString = tagArray.join().toLowerCase();
  };

  // New Tags
  const getTags = (tags) => {
    setTags(tags);
  };

  const handleSubmitM = (e) => {
    e.preventDefault();
    makeTagString();
    setFieldValue("Tags", tagString);
    handleSubmit();
  };

  // End Tags values

  const handleDrop = (acceptedFiles) => {
    // axios upload options
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
    };

    for (let i = 0; i < acceptedFiles.length; i += 1) {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[i]);

      reader.onloadend = (e) => {
        let imageCode = reader.result.toString().split(",")[1];
        console.log(imageCode);
        axios
          .post(
            API_URL + "Uploads/Photo",
            {
              Title: acceptedFiles[i].name.toString().split(".")[0],
              Code: imageCode,
              Type: "Product",
            },
            options
          )
          .then((res) => {
            toast.warn("Image Uploading");
            setUploadPercentage(0);
            let tile = {
              Id: res.data.Data.Id,
              Filename: res.data.Data.Filename,
              SeoFileName: res.data.Data.SeoFileName,
            };
            setAllImageIds((allImageIds) => [
              ...allImageIds,
              tile.Id.toString(),
            ]);
            setTileData((tileData) => [...tileData, tile]);
          });
      };
    }
  };

  const [pricing, setPricing] = React.useState("isFixed");

  const change = (name, e) => {
    if (name === "RootCategoryId") {
      let categoryId = e.target.value;

      console.log("s1 c: " + SubCategoryId);

      axios
        .get(API_URL + "ProductCategories/All?parentId=" + categoryId)
        .then((response) => {
          if (response.data.length != 0) {
            setHasSubCategory1(true);
            setHasSubCategory2(false);
            setSubCategories1(response.data);
          } else {
            setHasSubCategory1(false);
          }
        });
    }
    if (name === "SubCategoryId") {
      console.log("s1 o: " + SubCategoryId);
      let categoryId = e.target.value;
      axios
        .get(API_URL + "ProductCategories/All?parentId=" + categoryId)
        .then((response) => {
          if (response.data.length != 0) {
            setHasSubCategory2(true);
            setSubCategories2(response.data);
          } else {
            setHasSubCategory2(false);
          }
        });
    }

    if (name === "Pricing") {
      setPricing(e.target.value);
      setFieldValue(e.target.value, true);
    }

    if (name === "UsedStatus") {
      setIsUsed(!isUsed);
    }

    if (name === "UsedDurationUnit") {
      setUdUnit(e.target.value);
    }

    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const onKeyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };

  let onUpdateMyTag = (v) => {
    setTags(v);
  };

  return (
    <form
      className={classes.form}
      onKeyPress={onKeyPress}
      onSubmit={handleSubmitM}
      on
    >
      <Container maxWidth="sm">
        <Grid container>
          <Grid item sm={8} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  variant="outlined"
                  id="Title"
                  name="Title"
                  helperText={touched.Title ? errors.Title : ""}
                  error={errors.Title && Boolean(errors.Title)}
                  label="Title"
                  fullWidth
                  size="small"
                  value={Title}
                  onChange={change.bind(null, "Title")}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  select
                  id="RootCategoryId"
                  label="Select Category"
                  helperText={
                    touched.RootCategoryId ? errors.RootCategoryId : ""
                  }
                  error={
                    errors.RootCategoryId && Boolean(errors.RootCategoryId)
                  }
                  name="RootCategoryId"
                  value={RootCategoryId}
                  onChange={change.bind(null, "RootCategoryId")}
                  variant="outlined"
                  fullWidth
                  size="small"
                >
                  {rootCategories.map((option) => (
                    <MenuItem key={option.Id} value={option.Id}>
                      {option.Title}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
              {hasSubCategory1 ? (
                <Grid item xs={12}>
                  <CustomTextField
                    select
                    id="SubCategoryId"
                    label="Select Sub Category"
                    helperText={
                      touched.SubCategoryId ? errors.SubCategoryId : ""
                    }
                    error={
                      errors.SubCategoryId && Boolean(errors.SubCategoryId)
                    }
                    name="SubCategoryId"
                    value={SubCategoryId}
                    onChange={change.bind(null, "SubCategoryId")}
                    variant="outlined"
                    fullWidth
                    size="small"
                  >
                    {subCategories1 &&
                      subCategories1.map((option) => (
                        <MenuItem key={option.Id} value={option.Id}>
                          {option.Title}
                        </MenuItem>
                      ))}
                  </CustomTextField>
                </Grid>
              ) : null}

              {hasSubCategory2 ? (
                <Grid item xs={12}>
                  <CustomTextField
                    select
                    id="SubSubCategoryId"
                    label="Select Sub Category"
                    name="SubSubCategoryId"
                    value={SubSubCategoryId}
                    onChange={change.bind(null, "SubSubCategoryId")}
                    variant="outlined"
                    fullWidth
                    size="small"
                  >
                    {subCategories2.map((option) => (
                      <MenuItem key={option.Id} value={option.Id}>
                        {option.Title}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <RadioGroup
                  row
                  aria-label="position"
                  name="Pricing"
                  // defaultValue="isFixed"
                  value={pricing}
                  onChange={change.bind(null, "Pricing")}
                  helperText={touched.pricing ? errors.pricing : ""}
                  error={errors.pricing && Boolean(errors.pricing)}
                >
                  <FormControlLabel
                    value="isFixed"
                    control={<Radio color="default" />}
                    label="Fixed"
                  />
                  <FormControlLabel
                    value="IsNegotiable"
                    control={<Radio color="default" />}
                    label="Negotiable"
                  />
                  <FormControlLabel
                    value="IsPriceRange"
                    control={<Radio color="default" />}
                    label="Price Range"
                  />
                </RadioGroup>
              </Grid>

              {pricing !== "IsPriceRange" ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      variant="outlined"
                      id="AskingPrice"
                      name="AskingPrice"
                      type="number"
                      helperText={touched.AskingPrice ? errors.AskingPrice : ""}
                      error={errors.AskingPrice && Boolean(errors.AskingPrice)}
                      label="Asking Price"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      fullWidth
                      placeholder="0"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      // value={AskingPrice}
                      onChange={change.bind(null, "AskingPrice")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      variant="outlined"
                      id="MarketPrice"
                      type="number"
                      name="MarketPrice"
                      helperText={touched.MarketPrice ? errors.MarketPrice : ""}
                      error={errors.MarketPrice && Boolean(errors.MarketPrice)}
                      label="Market Price"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      fullWidth
                      size="small"
                      placeholder="0"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // value={MarketPrice}
                      onChange={change.bind(null, "MarketPrice")}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      variant="outlined"
                      id="PriceFrom"
                      name="PriceFrom"
                      type="number"
                      helperText={touched.PriceFrom ? errors.PriceFrom : ""}
                      error={errors.PriceFrom && Boolean(errors.PriceFrom)}
                      label="Starting Price"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      fullWidth
                      placeholder="0"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      // value={PriceFrom}
                      onChange={change.bind(null, "PriceFrom")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      variant="outlined"
                      id="PriceTo"
                      type="number"
                      name="PriceTo"
                      helperText={touched.PriceTo ? errors.PriceTo : ""}
                      error={errors.PriceTo && Boolean(errors.PriceTo)}
                      label="Ending Price"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      fullWidth
                      size="small"
                      placeholder="0"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // value={PriceTo}
                      onChange={change.bind(null, "PriceTo")}
                    />
                  </Grid>
                </>
              )}

              {/* product used or not part */}
              <Grid container item xs={12}>
                <Grid item xs={1}>
                  <Typography style={{ paddingTop: "7px" }} variant="subtitle2">
                    Used:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    checked={isUsed}
                    onChange={change.bind(null, "UsedStatus")}
                    name="UsedStatus"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
                {isUsed ? (
                  <>
                    <Grid item xs={3}>
                      <CustomTextField
                        variant="outlined"
                        id="UsedDuration"
                        type="number"
                        name="UsedDuration"
                        helperText={
                          touched.UsedDuration ? errors.UsedDuration : ""
                        }
                        error={
                          errors.UsedDuration && Boolean(errors.UsedDuration)
                        }
                        label="Duration"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        fullWidth
                        size="small"
                        placeholder="0"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // value={UsedDuration}
                        onChange={change.bind(null, "UsedDuration")}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="UsedDurationUnit"
                        value={udUnit}
                        onChange={change.bind(null, "UsedDurationUnit")}
                        helperText={
                          touched.UsedDurationUnit
                            ? errors.UsedDurationUnit
                            : ""
                        }
                        error={
                          errors.UsedDurationUnit &&
                          Boolean(errors.UsedDurationUnit)
                        }
                        style={{ paddingLeft: 10 }}
                      >
                        <FormControlLabel
                          value="months"
                          control={<Radio color="default" />}
                          label="Month"
                        />
                        <FormControlLabel
                          value="years"
                          control={<Radio color="default" />}
                          label="Year"
                        />
                      </RadioGroup>
                    </Grid>
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  rows="4"
                  rowsMax="50"
                  variant="outlined"
                  fullWidth
                  size="small"
                  helperText={touched.Description ? errors.Description : ""}
                  error={errors.Description && Boolean(errors.Description)}
                  id="Description"
                  value={Description}
                  label="Description"
                  name="Description"
                  onChange={change.bind(null, "Description")}
                />
              </Grid>

              {/* <TagInput  suggestionsTag={suggestionsTag}></TagInput> */}
              {/* <Grid item xs={12}>
                <p>Enter new tags meeting the requirements below:</p>
                <ReactTags
                  allowNew={true}
                  newTagPrefix="Create new tag: "
                  ref={reactTags}
                  tags={tags}
                  suggestions={suggestionsTag}
                  onDelete={onDelete}
                  onAddition={onAddition}
                  name="mTag"
                  onChange={change.bind(null, "mTag")}
                />
              </Grid> */}

              <Grid item xs={12}>
                {/* <InputTags
                  onTag={getTags}
                  tagColor="gray"
                  placeHolder="Press enter to add tags"
                /> */}
                <MyTag
                  onUpdateMyTag={onUpdateMyTag}
                  Tags={tags}
                  suggestionsTag={suggestionsTag}
                ></MyTag>
              </Grid>

              <Grid item xs={12}>
                {shopList.length > 1 && (
                  <CustomTextField
                    InputProps={{
                      classes: {
                        root: classes.textFieldInput,
                      },
                    }}
                    select
                    id="ShopId"
                    label="Select Shop"
                    name="ShopId"
                    value={ShopId}
                    onChange={change.bind(null, "ShopId")}
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.marginBottom}
                    helperText={touched.ShopId ? errors.ShopId : ""}
                    error={errors.ShopId && Boolean(errors.ShopId)}
                  >
                    {shopList.map((option) => (
                      <MenuItem key={option.Id} value={option.Id}>
                        {option.Name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={4} md={4} style={{ paddingLeft: "20px" }}>
            <Dropzone
              onDrop={handleDrop}
              accept="image/*"
              minSize={1024}
              maxSize={3072000}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
              }) => {
                // additional CSS depends on dragging status
                const additionalClass = isDragAccept
                  ? "accept"
                  : isDragReject
                  ? "reject"
                  : "";

                return (
                  <div
                    {...getRootProps({
                      className: `dropzone ${additionalClass}`,
                    })}
                  >
                    <input {...getInputProps()} />
                    <span>{isDragActive ? "📂" : "📁"}</span>
                    <Typography variant="body1" color="primary">
                      Drag'n'Drop or Click to Upload Images
                    </Typography>
                  </div>
                );
              }}
            </Dropzone>
            {uploadPercentage > 0 ? (
              <CircularProgressbar
                value={uploadPercentage}
                text={`${uploadPercentage}%`}
              />
            ) : null}
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="sm" style={{ paddingTop: 20 }}>
        <Grid container>
          <Grid item md={12}>
            <GridList cols={4} rows={2} cellHeight={80}>
              {tileData.map((tile, i) => (
                <GridListTile
                  style={{ display: "flex", flexFlow: "no-wrap column" }}
                  key={i}
                >
                  <img
                    src={
                      IMAGE_URL +
                      new Date().getFullYear() +
                      (new Date().getMonth() + 1).toString().padStart(2, "0") +
                      "/product/200/" +
                      tile.Filename
                    }
                    alt={tile.SeoFileName}
                  />
                </GridListTile>
              ))}
            </GridList>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.flexContainer}>
        <div className={classes.triangleBottomRight}>
          <Input type="hidden" name="AllImageIds" />
          <Button
            onClick={() => {
              setFieldValue("AllImageIds", { allImageIds });
            }}
            type="submit"
            color="secondary"
            style={{ borderRadius: 25, textTransform: "none", width: 130 }}
            disabled={!isValid}
            variant="contained"
          >
            Post Ad
          </Button>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </form>
  );
};

export default PostAdForm;
