import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
} from "@material-ui/core";
import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_URL } from "../../Constants";
import ConfirmDialog from "../utilities/ConfirmDialog";
import { useHistory } from "react-router";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },

  hoverBtn: {
    borderRadius: 25,
    textTransform: "none",
    "&:hover": {
      background: "#D1152C0D",
      //   color: "#ffffff",
      border: "1px solid #D1152C",
    },
  },
});

// Calculation
function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

// function priceRow(qty, unit) {
//   return qty * unit;
// }

// function createRow(desc, qty, unit) {
//   const price = priceRow(qty, unit);
//   return { desc, qty, unit, price };
// }

// const rows = [
//   createRow("Type 1", 100, 2),
//   createRow("Type 2", 10, 2),
//   createRow("Type 3", 2, 2),
// ];

// function subtotal(items) {
//   return items
//     .map(({ CashAmount }) => CashAmount)
//     .reduce((sum, i) => sum + i, 0);
// }

// const invoiceSubtotal = subtotal(rows);
// const invoiceTaxes = 1 * invoiceSubtotal;
// const invoiceTotal = invoiceTaxes + invoiceSubtotal;
// End Calculatio

export default function DuePayment() {
  const classes = useStyles();
  const history = useHistory();

  let [points, setPoints] = React.useState([]);
  let [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios
      .get(
        API_URL +
          "/UserInfoes/PaymentPendingList?userId=" +
          JSON.parse(localStorage.getItem("user")).Id
      )
      .then((res) => {
        setPoints(res.data);
        setLoading(false);
        console.log("pay :" + JSON.stringify(res.data));
      });
  }, []);

  //Payment Request Button activity
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handlePaymentRequest = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    axios
      .get(
        API_URL +
          "/UserInfoes/PaymentRequest?userId=" +
          JSON.parse(localStorage.getItem("user")).Id
      )
      .then((res) => {
        messaginForma("Request submit Successfully", "green");
        setTimeout(() => {
          history.push("/redirect-payment");
        }, 1000);
      })
      .catch((err) => {
        messaginForma("Something went wrong", "#d1152c");
      });
  };
  //Payment Request Button activity end

  //Message Part
  let [isMessage, setIsMessage] = React.useState(false);
  let [myMessage, setMyMessage] = React.useState();
  let [myMessageColor, setMyMessageColor] = React.useState();

  let messaginForma = (myMes, myColor) => {
    setMyMessageColor(myColor);
    setIsMessage(true);
    setMyMessage(myMes);
    setTimeout(() => {
      setIsMessage(false);
      setMyMessage();
    }, 4000);
  };

  return (
    <>
      <TableContainer component={Paper} style={{ marginTop: "10px" }}>
        <p style={{ textAlign: "center" }}>Due Payment</p>
        {loading ? (
          <Typography variant="body2">
            <h4>Loading...</h4>
          </Typography>
        ) : (
          <>
            {points.length == 0 ? (
              <p style={{ textAlign: "center", color: "#d1152c" }}>
                No Due Payment Available
              </p>
            ) : (
              <>
                {isMessage && (
                  <p
                    style={{
                      color: myMessageColor,
                      textAlign: "center",
                    }}
                  >
                    {myMessage}
                  </p>
                )}
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Point Type</StyledTableCell>
                      <StyledTableCell align="right">
                        Reward Points
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Cash Amount (Tk.)
                      </StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {points.map((row) => (
                      <StyledTableRow key={row.ReferredByUserId}>
                        <StyledTableCell component="th" scope="row">
                          {row.PointType}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.RewardPoints}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {ccyFormat(row.CashAmount)}
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </StyledTableRow>
                    ))}
                    <TableRow>
                      <StyledTableCell colSpan={2}>Total</StyledTableCell>
                      <StyledTableCell align="right">
                        {ccyFormat(
                          points
                            .map(({ CashAmount }) => CashAmount)
                            .reduce((sum, i) => sum + i, 0)
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          onClick={(e) =>
                            setConfirmDialog({
                              isOpen: true,
                              title:
                                "Are you sure to proceed this payment request?",
                              subTitle: "You can't undo this operation",
                              onConfirm: () => {
                                handlePaymentRequest(e);
                              },
                            })
                          }
                          className={classes.hoverBtn}
                          size="small"
                          variant="outlined"
                        >
                          Payment Request
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
          </>
        )}
      </TableContainer>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
