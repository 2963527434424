import { Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../Constants";
import TeamConnectionsList from "./TeamConnectionsList";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: "20px",
  },
}));

const ConnectionsListForTeam = (props) => {
  const teamId = useParams();
  const classes = useStyles();
  const history = useHistory();

  let [isMessage, setIsMessage] = React.useState(false);
  let [myMessage, setMyMessage] = React.useState();
  let [myMessageColor, setMyMessageColor] = React.useState();
  const loginUId = JSON.parse(localStorage.getItem("user")).Id;

  let getUId = (item) => {
    let uId;
    item.ConnectedBy === loginUId
      ? (uId = item.ConnectedWith)
      : (uId = item.ConnectedBy);

    return uId;
  };

  const addPeople = (item) => {
    let uId = getUId(item);

    console.log("a p: " + uId, teamId.id, loginUId);

    let teamInfo = {
      UserTeamId: teamId.id,
      UserInfoId: uId,
      TeamActiveStatus: true,
    };

    axios
      .post(API_URL + "UserTeamToUserInfoMappings", teamInfo)
      .then((response) => {
        if (response.data.Meta.Status === 1) {
          console.log("added");
          messaginForma("Member added Successfully", "green");
        } else {
          messaginForma("Something went wrong", "#d1152c");
        }
      });
  };

  const removePeople = (item) => {
    let uId = getUId(item);
    console.log("r p: " + uId, teamId.id);

    axios
      .delete(
        API_URL +
          "UserTeamToUserInfoMappings/ByUser?userId=" +
          uId +
          "&teamId=" +
          teamId.id
      )
      .then((response) => {
        console.log("S :" + JSON.stringify(response));
        if (response.status === 200) {
          messaginForma("Remove member Successfully", "green");
        } else {
          messaginForma("Something went wrong", "#d1152c");
        }
      });
  };

  let messaginForma = (myMes, myColor) => {
    setMyMessageColor(myColor);
    setIsMessage(true);
    setMyMessage(myMes);

    setTimeout(() => {
      setIsMessage(false);
      setMyMessage();
    }, 4000);
  };

  return (
    <Paper className={classes.marginBottom}>
      {/* <Paper style={{ flexGrow: 1, margin: "10px 0" }}>
        <Tabs indicatorColor="primary" textColor="#D1152C" centered>
          <Tab
            style={{ textTransform: "none", color: "#D1152C" }}
            label="Connections"
          />
        </Tabs>
      </Paper> */}

      {isMessage && (
        <p
          style={{
            color: myMessageColor,
            textAlign: "center",
            paddingTop: "10px",
          }}
        >
          {myMessage}
        </p>
      )}

      <TeamConnectionsList
        isTeam={true}
        addPeople={addPeople}
        removePeople={removePeople}
        teamId={teamId.id}
      ></TeamConnectionsList>

      <ToastContainer autoClose={2000} />
    </Paper>
  );
};

export default ConnectionsListForTeam;
