import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, Route } from "react-router-dom";
import { Grid, Typography, Container} from "@material-ui/core";
import LeftSideBar from "../components/LeftSideBar";
import {useHistory} from 'react-router-dom';

import { grey} from '@material-ui/core/colors';
import NavBar from "../components/Navbar";
import BannerTop from "../images/shop-banner.png"
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";

const useStyles = makeStyles(theme => ({
  box:{
    position: 'relative'
},
text:{
    position: 'absolute',
    zIndex: 999,
    margin: '0 auto',
    left: 0,
    right: '40%',
    top:'70%',      
    textAlign: 'center',
    fontFfamily: 'Arial,sans-serif',
    color: '#fff',
   
}

}));
const SecondaryLayoutFooter = ({ children, ...rest }) => {
  const classes = useStyles();
  const history=useHistory();
  let { guid } = useParams();
  const [isAuthenticated,setIsAuthenticated]=React.useState(false)

  React.useEffect(()=>{
  
   if(localStorage.getItem('user')) setIsAuthenticated(true)
  },[])

const  handleAuthenticate=()=>{
    setIsAuthenticated(false)
  }
 

  let [text,setText]=React.useState('')
 
  
  React.useEffect(()=>{
    console.log(history.location.pathname)
    switch(history.location.pathname){
            case '/about':
            setText('About Us')
            break;
            case '/contact-us':
            setText('Contact Us')
            break;
            case '/privacy-policy':
            setText('Privacy Policy')
            break;
            case '/terms-conditions':
            setText('Terms & Conditions')
            break;
            case '/privacy':
            setText('Privacy')
            break;
           
       default:
         setText('About');
         break;
   }
  })
  return (
    <div style={{backgroundColor:'#F2F2F2',width: '100%', height: '100%',position: 'absolute', top: 0, left: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'}}>
      <Grid container >
          <Grid item> <NavBar authenticate={handleAuthenticate} isAuthenticated={isAuthenticated} /></Grid>
      </Grid>
      <Grid className={classes.box} container style={{paddingTop: '96px'}}  >
        <img src={BannerTop} width="100%" alt="banner-shop"/>
        <div className={classes.text}>
            <Typography variant="h6">{text}</Typography>
        </div>
      </Grid>

      <Container maxWidth="xl" style={{backgroundColor:'#F2F2F2'}}>
    <Grid container spacing={1}>
      <Grid item xl={3} lg={3} md={3} sm={3}>
      <LeftSideBar isAuthenticated={isAuthenticated}/>
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <div className="main">{children}</div>
          </Grid>
        </Grid>
      </Grid>

      <Grid  item xl={3} lg={3} md={3} sm={3}>
       
            {/* <Typography  variant="body2">© 2020 - SundarbanX</Typography> */}
            <Footer></Footer>
        
      </Grid>
    </Grid>
    </Container>
    </div>
  );
};

const SecondaryLayoutFooterRoute = ({ component: Component, ...rest }) => {
  const history=useHistory();
  return (
    <Route
      {...rest}
      render={matchProps => (
        
        <SecondaryLayoutFooter>
          <Component {...matchProps} />
        </SecondaryLayoutFooter>
        
      )}
    />
  );
};

export default SecondaryLayoutFooterRoute;
